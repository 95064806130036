<template>
  <v-app id="appbarprovider" style="background: #f4f5fa">
    <v-navigation-drawer
      app
      dark
      permanent
      v-model="drawer"
      width="300"
      :mini-variant.sync="mini"
      color="#0a1a2a"
    >
      <div class="main-menu-content" id="main-menu-navigation">
        <v-list link class="navigation navigation-main">
          <div style="height: 80px" class="d-flex">
            <v-list-item class="nav-item mb-2" color="#08121e" @click="gotopropmanage()">
              <img :src="require('@/assets/images/icons/Logo-07.png')" width="35" />
              <img
                :src="require('@/assets/images/icons/Logo-01.png')"
                class="ml-4"
                width="120"
              />
            </v-list-item>
          </div>
          <div v-for="item in itemsNavList" :key="item._id">
            <div v-if="mini === false">
              <v-list-item
                v-if="!item.submenu"
                link
                :class="[
                  currentPage.includes(item.link) ? 'activeClass' : 'inactiveClass',
                ]"
                class="nav-item mb-2"
                @click="gotolink(item)"
              >
                <v-icon>{{ item.icon }}</v-icon>
                <span class="menu-title ml-3">{{ item.name }}</span>
              </v-list-item>
              <div v-else-if="item.submenu">
                <v-list-group
                  link
                  class="nav-item"
                  :class="[
                    currentPage.includes(item.link) ? 'activeClass' : 'inactiveClass',
                  ]"
                  color="white"
                  :value="listGroup"
                >
                  <template v-slot:activator>
                    <v-icon>{{ item.icon }}</v-icon>
                    <span class="menu-title ml-3">{{ item.name }}</span>
                    <v-list-item-content></v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="child in item.submenu"
                    :key="child._id"
                    @click="gotolink(child)"
                  >
                    <span class="menu-title ml-3">{{ child.name }}</span>
                  </v-list-item>
                </v-list-group>
              </div>
            </div>
            <div v-else>
              <v-menu
                :open-on-hover="true"
                :close-on-content-click="false"
                :nudge-width="180"
                class="mt-1"
                color="#2C343A"
                tile
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item
                    link
                    v-bind="attrs"
                    v-on="on"
                    class="nav-item mb-2"
                    :class="[
                      currentPage.includes(item.link) ? 'activeClass' : 'inactiveClass',
                    ]"
                    @click="gotolink(item)"
                  >
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item>
                </template>
                <div class="menu-list">
                  <div v-if="item.submenu">
                    <div class="header-list pa-2">
                      <span class="menu-title ml-3">{{ item.name }}</span>
                    </div>
                    <div
                      v-for="child in item.submenu"
                      :key="child._id"
                      @click="gotolink(child)"
                      class="sub-list pa-2"
                    >
                      <span class="span-sub-list">{{ child.name }}</span>
                    </div>
                  </div>
                  <div
                    v-else-if="!item.submenu"
                    @click="gotolink(item)"
                    class="header-list pa-2"
                  >
                    <span class="menu-title ml-3">{{ item.name }}</span>
                  </div>
                </div>
              </v-menu>
            </div>
          </div>
        </v-list>
      </div>
    </v-navigation-drawer>
    <v-app-bar app height="80px" color="white">
      <v-row row wrap class="px-4 align-center">
        <v-btn icon small>
          <v-icon color="#6b6f82" @click="stopmini">mdi-menu</v-icon>
        </v-btn>
        <v-btn icon small class="ml-6">
          <v-icon color="#6b6f82">mdi-magnify</v-icon>
        </v-btn>
        <v-spacer />
        <div class="appbar-menu">
          <span class="label text-dark">{{ checkPropertyNameFunc() }}</span>
        </div>
        <v-spacer />
        <ul class="nav d-flex flex-row align-center">
          <v-menu v-model="showUserMenu" left offset-y :nudge-width="200">
            <template v-slot:activator="{ on, attrs }">
              <v-badge bordered bottom dot color="#28d094" offset-x="10" offset-y="10">
                <v-avatar size="40" v-bind="attrs" v-on="on">
                  <img
                    :src="
                      coverImage
                        ? coverImage
                        : require('@/assets/img/thumb/placeholder-image.png')
                    "
                    alt
                  />
                </v-avatar>
              </v-badge>
            </template>
            <v-list>
              <v-list-item>
                <v-row row wrap>
                  <v-col cols="12" align="center" class="mt-3">
                    <p
                      @click="showUserMenu = false"
                      style="margin-bottom: -15px; float: right; cursor: pointer"
                    >
                      <v-icon small color="#6b6f82">mdi-window-close</v-icon>
                    </p>
                    <v-badge
                      bordered
                      bottom
                      dot
                      color="#28d094"
                      offset-x="10"
                      offset-y="10"
                    >
                      <v-avatar size="50">
                        <img
                          alt
                          :src="
                            coverImage
                              ? coverImage
                              : require('@/assets/img/thumb/placeholder-image.png')
                          "
                        />
                      </v-avatar>
                    </v-badge>

                    <p style="font-size: 13px; margin: 10px 0 5px 0">{{ userEmail }}</p>
                    <div class="d-flex justify-content-center">
                      <a
                        class="btn btn-dark btn-block mt-1"
                        style="
                          width: 7.5rem !important;
                          height: 2.625rem !important;
                          color: #fff !important;
                        "
                        @click="goexit()"
                        >{{ $t("SigninToolbar.signout") }}</a
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-divider v-if="!propertyData.voucher_suspend" class="mb-2"></v-divider>
              <div v-if="!propertyData.voucher_suspend" class="user-menu pa-2">
                <p
                  v-if="checkRoleUserFunc() == 'provider' && !overlay"
                  @click="gotopropmanage()"
                >
                  <v-icon left small>mdi-plus-circle-outline</v-icon>
                  <span class="mt-1">{{ $t("SigninToolbar.add_new_property") }}</span>
                </p>
                <p v-if="!overlay" @click="gotoinviteuserFunc()">
                  <v-icon left small>mdi-plus-circle-outline</v-icon>
                  <span class="mt-1">{{ $t("SigninToolbar.invite_new_contact") }}</span>
                </p>
                <p @click="gotopropmanage()" class="mb-0">
                  <v-icon left small>mdi-swap-horizontal</v-icon>
                  <span>{{ $t("SigninToolbar.switch_property") }}</span>
                </p>
              </div>
            </v-list>
          </v-menu>

          <v-menu offset-y class="menu-language">
            <template v-slot:activator="{ on, attrs }">
              <li class="nav-lang ml-6">
                <a v-bind="attrs" v-on="on">
                  <img :src="selectedLanguage.img" />
                </a>
              </li>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in itemsLanguage" :key="index">
                <v-list-item-title
                  class="change-language px-2"
                  @click="changeLangFunc(item)"
                >
                  <img :src="item.img" class="mr-3" />
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- bagde notification -->
          <v-menu v-if="!propertyData.voucher_suspend" offset-y class="menu-language">
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                :value="remainDayOut"
                bordered
                overlap
                dot
                color="error"
                class="ml-6"
              >
                <v-icon color="#6b6f82" v-bind="attrs" v-on="on">mdi-bell-outline</v-icon>
              </v-badge>
            </template>
            <v-list>
              <v-list-item v-if="remainDayOut">
                <v-row row wrap>
                  <v-col
                    cols="12"
                    class="my-2 alert-team-purchase"
                    @click="gotoTeamPurchaseFunc()"
                  >
                    <span>
                      <v-icon x-small class="mr-2" color="error">mdi-alert-circle</v-icon>
                      <span class="text-dark mr-1">{{
                        $t("SigninTeamPurchase.title")
                      }}</span>
                      <span class="text-bold text-error">{{
                        $t("SigninTeamPurchase.expired_campaign")
                      }}</span>
                    </span>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item v-else>
                <v-row row wrap>
                  <v-col cols="12">
                    <span class="text-gray">{{ $t("RateAndInventory.nodata") }}</span>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-menu>
        </ul>

        <v-btn v-if="!propertyData.voucher_suspend" icon small class="ml-6">
          <v-icon color="#6b6f82">mdi-email-outline</v-icon>
        </v-btn>
      </v-row>
    </v-app-bar>
    <v-main class="position-vmain">
      <div>
        <v-container
          fluid
          class="content-wrapper overflow-hidden"
          style="position: relative"
        >
          <div class="content-body" ref="BulkEditMenuContainer">
            <div class="row match-height">
              <div class="col-12 d-flex flex-row justify-start align-center">
                <h4 class="fw-600">{{ $t("SigninCalendar.title") }}</h4>
                <v-btn
                  icon
                  class="ml-3"
                  @click="initAll()"
                  :class="loadingOverlay && 'animate__spining'"
                  :disabled="loadingOverlay"
                >
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </div>
              <div class="form-group col-md-3">
                <div
                  class="dropdown select"
                  @click="getfocus('select_filter_room')"
                  @mouseleave="mouseOver('select_filter_room')"
                >
                  <a id="select_filter_room">
                    <v-select
                      v-model="select_filter_room"
                      :items="itemsFilterRooms"
                      item-text="name"
                      item-value="value"
                      label
                      :placeholder="$t('SignupStep.select_one')"
                      :no-data-text="$t('SignupStep.nodata')"
                      color="#1e9ff2"
                      solo
                      flat
                      dense
                      single-line
                      hide-details
                      @change="filterRoomsFunc()"
                    >
                      <template v-slot:item="data">
                        <v-list-item-content
                          @mouseenter="getfocus('select_filter_room')"
                          @mouseleave="mouseOver('select_filter_room')"
                        >
                          <v-list-item-title
                            v-html="data.item.name"
                            class="title-signup-sj"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </a>
                </div>
              </div>
              <div class="form-group col-auto d-flex justify-start align-center">
                <div class="custom-control custom-checkbox">
                  <input
                    id="cb_priceadjust"
                    name="priceadjust"
                    type="checkbox"
                    class="custom-control-input"
                    :value="cb_priceadjust"
                    :checked="cb_priceadjust"
                    @change="checkPriceAdjust()"
                  />
                  <label class="custom-control-label" for="cb_priceadjust">{{
                    $t("SigninCalendar.tt_priceadjust")
                  }}</label>
                </div>
              </div>
              <div class="form-group col-auto d-flex justify-start align-center">
                <div class="custom-control custom-checkbox">
                  <input
                    id="cb_reservereq"
                    name="reservereq"
                    type="checkbox"
                    class="custom-control-input"
                    :value="cb_reservereq"
                    :checked="cb_reservereq"
                    @change="checkReserveReq()"
                  />
                  <label class="custom-control-label" for="cb_reservereq">{{
                    $t("SigninCalendar.tt_reservationreq")
                  }}</label>
                </div>
              </div>
            </div>

            <div class="row pt-1">
              <div class="col-12">
                <!-- <div class="d-flex flex-row align-center justify-end">
                  <span class="mr-5">{{
                    $t("SigninCalendar.confirm_reservation_manually")
                  }}</span>
                  <v-switch
                    :loading="loadingManualCheckin"
                    v-model="manualCheckinValue"
                    inset
                    color="success"
                    label=""
                    @change="updateManualCheckinFunc()"
                  ></v-switch>
                </div> -->
                <div class="card card-shadow">
                  <div class="card-body">
                    <div class="table-responsive" style="overflow-y: hidden">
                      <table style="width: 100% !important">
                        <thead>
                          <tr id="calendar-header-month">
                            <td style="border: none"></td>
                            <td
                              v-for="(groupHead, index) in groupHeaders"
                              v-show="groupHead"
                              :id="groupHead.month_year"
                              :key="groupHead.month_year"
                              style="border: none"
                              class="text-left color-black"
                              :class="
                                index == groupHeaders.length - 1
                                  ? 'fixed-last-month'
                                  : 'fixed-month'
                              "
                              :colspan="groupHead.counts"
                            >
                              <b>{{ groupHead.name }}</b>
                            </td>
                          </tr>
                          <tr id="calendar-header">
                            <td style="border: none">
                              <div class="row">
                                <!-- <div
                                  class="col-8 px-0"
                                  style="
                                    width: 20rem;
                                    position: sticky;
                                    left: 0;
                                    background-color: #ffff;
                                  "
                                >
                                  <v-menu
                                    ref="selectdaterange"
                                    v-model="menu_daterange"
                                    :close-on-content-click="false"
                                    :return-value.sync="select_daterange"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <div class="form-group mb-0">
                                        <v-text-field
                                          id="select_weekrange"
                                          v-model="dateRangeText"
                                          label
                                          class="form-control"
                                          readonly
                                          solo
                                          flat
                                          dense
                                          single-line
                                          hide-details
                                          v-bind="attrs"
                                          v-on="on"
                                          style="
                                            width: 100% !important;
                                            height: 2.9rem;
                                          "
                                        ></v-text-field>
                                      </div>
                                    </template>
                                    <v-date-picker v-model="select_daterange" no-title scrollable>
                                      <v-spacer></v-spacer>
                                      <v-btn text color="primary" @click="menu_daterange = false">
                                        {{
                                        $t("SigninCalendar.btn_cancel")
                                        }}
                                      </v-btn>
                                      <v-btn text color="primary" @click="saveSelectDateRange()">
                                        {{
                                        $t("SigninCalendar.btn_apply")
                                        }}
                                      </v-btn>
                                    </v-date-picker>
                                  </v-menu>
                                </div>-->
                                <div class="col-11 py-0" style="min-width: 18.5rem">
                                  <v-btn
                                    @click="getToday"
                                    depressed
                                    dark
                                    color="#424242"
                                    elevation="0"
                                    class="mb-0"
                                    block
                                    style="height: 3rem"
                                  >
                                    <span class="textsyle">{{
                                      $t("SigninCalendar.today")
                                    }}</span>
                                  </v-btn>
                                </div>
                                <!-- <div
                                  class="col-1 px-0"
                                  style="
                                    width: 20rem;
                                    position: sticky;
                                    left: 0;
                                    background-color: #ffff;
                                  "
                                >
                                </div>-->
                              </div>
                            </td>
                            <td
                              v-for="(header, index) in headers"
                              v-show="header"
                              :key="header"
                              id="header"
                              :class="index == 0 && 'border-now'"
                              style="border: 1px solid #eee"
                            >
                              <div class="table-cell-width bg-white">
                                {{ showDaysHeader(header) }}
                                <br />
                                <small>{{ showDOHeader(header) }}</small>
                              </div>
                            </td>
                          </tr>
                        </thead>

                        <tbody v-if="!overlay && itemsCalendar.length">
                          <template v-for="(ele, index) in itemsCalendar">
                            <tr :key="ele._id">
                              <td :colspan="headers.length + 1" style="border: none">
                                <div
                                  class="py-2"
                                  style="
                                    max-width: 90vw;
                                    position: sticky;
                                    left: 0px;
                                    background-color: rgb(255, 255, 255);
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                  "
                                >
                                  <div
                                    class="color-gray float-left"
                                    style="position: sticky; left: 0"
                                  >
                                    <span class="title-room">{{
                                      ele.default_room.room_name.value
                                    }}</span>
                                    <span>
                                      ({{ $t("SigninCalendar.title_roomid") }}:
                                      {{ ele._id }})
                                    </span>
                                  </div>
                                  <div
                                    v-if="!manualCheckinValue"
                                    class
                                    style="position: sticky; right: 0"
                                  >
                                    <v-btn
                                      class="btn btn-warning btn-min-width my-2"
                                      depressed
                                      dark
                                      @click="toggleBulkEditMenu(ele)"
                                    >
                                      {{ $t("SigninCalendar.btn_bulk_edit") }}
                                    </v-btn>
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <tr :key="`${index}_0`">
                              <th
                                scope="row"
                                class="table-td-border"
                                style="
                                  width: 20rem;
                                  position: sticky;
                                  left: 0;
                                  background-color: #ffff;
                                "
                              >
                                <span class="text-h td-title">
                                  {{ $t("SigninCalendar.bulk_room_status") }}
                                </span>
                              </th>

                              <td
                                v-for="(el, i) in ele.room_dates_status"
                                :key="`${index}_0${i}`"
                                :class="[
                                  checkRoomStatusFunc(el) ==
                                    $t('SigninCalendar.bulk_not_reserve_room') &&
                                    'inventory',
                                  checkRoomStatusFunc(el) ==
                                    $t('SigninCalendar.status_no_inventory') &&
                                    'no-inventory',
                                  i == 0 && 'begin',
                                  i == ele.room_dates_status.length - 1 && 'end',
                                  i > 0 &&
                                    checkRoomStatusFunc(el) !==
                                      checkRoomStatusFunc(ele.room_dates_status[i - 1]) &&
                                    'begin',
                                  i < ele.room_dates_status.length - 1 &&
                                    checkRoomStatusFunc(el) !==
                                      checkRoomStatusFunc(ele.room_dates_status[i + 1]) &&
                                    'end',
                                ]"
                                class="event"
                              >
                                {{
                                  i == 0
                                    ? checkRoomStatusFunc(el)
                                    : i > 0 &&
                                      checkRoomStatusFunc(el) !==
                                        checkRoomStatusFunc(ele.room_dates_status[i - 1])
                                    ? checkRoomStatusFunc(el)
                                    : ""
                                }}
                              </td>
                            </tr>

                            <tr :key="`${index}_1`">
                              <th
                                scope="row"
                                class="table-td-border"
                                style="
                                  width: 20rem;
                                  position: sticky;
                                  left: 0;
                                  background-color: #ffff;
                                "
                              >
                                <span class="text-h td-title">
                                  {{ $t("SigninCalendar.bulk_room_tosell") }}
                                </span>
                              </th>

                              <v-menu
                                v-model="ele.showEditPrice"
                                absolute
                                offset-y
                                min-width="400"
                                style="border: none !important"
                                :close-on-content-click="closeOnEditRoom"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <td
                                    v-for="(el, i) in ele.room_dates"
                                    :key="`${index}_1${i}`"
                                    :id="`${index}_1${i}`"
                                    class="table-td-border bg-white text-right pr-3"
                                    :class="[
                                      el.date == headers[0] && 'border-now',
                                      (el.room_status == 'inactive' ||
                                        el.room_status == 'noinventory') &&
                                        'bg-table-red',
                                    ]"
                                    @click="
                                      [
                                        getfocusEditRoom(`${index}_1${i}`, attrs, on),
                                        editRoomFunc(el, i, ele, index),
                                      ]
                                    "
                                    @mouseleave="getfocusEditRoom(`${index}_1${i}`)"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <span class>{{ el.room_number_remain }}</span>
                                  </td>
                                </template>
                                <div v-if="loadingEditRoomNumber">
                                  <v-skeleton-loader
                                    type="article, actions"
                                  ></v-skeleton-loader>
                                </div>
                                <div v-else class="bg-white pa-4">
                                  <div
                                    class="d-flex flex-row justify-space-between"
                                    :class="
                                      edit_room_number_remain <= 0
                                        ? 'align-start'
                                        : 'align-center'
                                    "
                                    style="width: 100%"
                                  >
                                    <div
                                      class="d-flex flex-column"
                                      :class="
                                        manualCheckinValue &&
                                        edit_room_number_remain <= 0 &&
                                        'mb-3'
                                      "
                                    >
                                      <h5 class="text-bold">
                                        {{ ele.default_room.room_name.value }}
                                      </h5>
                                      <small
                                        v-if="
                                          (edit_room_number_remain <= 0 ||
                                            edit_room_status == 'inactive') &&
                                          editRoomDates &&
                                          edit_room_number == editRoomDates.room_number
                                        "
                                        class="text-error"
                                      >
                                        ({{
                                          $t(
                                            edit_room_status == "inactive"
                                              ? "SigninCalendar.bulk_not_reserve_room"
                                              : "SigninCalendar.status_no_inventory"
                                          )
                                        }})
                                      </small>
                                    </div>
                                    <v-btn icon @click="editRoomFunc()">
                                      <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                  </div>
                                  <div
                                    class="d-flex flex-row align-center justify-space-between"
                                    style="width: 100%"
                                  >
                                    <h5 class="text-bold">{{ dateEditRoomText() }}</h5>
                                    <v-btn
                                      v-if="!manualCheckinValue"
                                      class="btn btn-warning btn-min-width my-2"
                                      depressed
                                      dark
                                      @click="[editRoomFunc(), toggleBulkEditMenu(ele)]"
                                    >
                                      {{ $t("SigninCalendar.btn_bulk_edit") }}
                                    </v-btn>
                                  </div>
                                  <v-radio-group
                                    row
                                    v-model="edit_room_status"
                                    :key="componentEditRoom"
                                    @change="selectEditRoomStatusFunc()"
                                    :disabled="manualCheckinValue"
                                  >
                                    <v-radio
                                      :label="`${$t(
                                        'SigninCalendar.bulk_can_reserve_room'
                                      )}`"
                                      value="active"
                                      color="#1e9ff2"
                                      class="font-14px"
                                    ></v-radio>
                                    <v-radio
                                      :label="`${$t(
                                        'SigninCalendar.bulk_not_reserve_room'
                                      )}`"
                                      value="inactive"
                                      color="#1e9ff2"
                                      class="font-14px"
                                    ></v-radio>
                                    <v-radio
                                      :label="`${$t(
                                        'SigninCalendar.status_no_inventory'
                                      )}`"
                                      value="noinventory"
                                      color="#1e9ff2"
                                      class="font-14px"
                                      disabled
                                    ></v-radio>
                                  </v-radio-group>
                                  <v-row class="mt-3 align-center">
                                    <v-col cols="12" sm="12" md="6" lg="6">
                                      <label
                                        for="issueinput3"
                                        class="font-weight-bold font-14px"
                                      >
                                        {{ $t("SigninCalendar.bulk_room_tosell") }}
                                      </label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6">
                                      <div
                                        class="dropdown select"
                                        @click="getfocus('edit_room_number')"
                                        @mouseleave="mouseOver('edit_room_number')"
                                      >
                                        <a id="edit_room_number">
                                          <v-select
                                            v-model="edit_room_number"
                                            :items="itemsRoomSell"
                                            label
                                            :placeholder="$t('SignupStep.select_one')"
                                            :no-data-text="$t('SignupStep.nodata')"
                                            color="#1e9ff2"
                                            solo
                                            flat
                                            dense
                                            single-line
                                            hide-details
                                            @change="selectEditRoomNumberFunc()"
                                            :loading="loadingEditRoomNumber"
                                            :disabled="
                                              edit_room_status == 'inactive' ||
                                              manualCheckinValue
                                            "
                                          >
                                            <template v-slot:item="data">
                                              <v-list-item-content
                                                @mouseenter="getfocus('edit_room_number')"
                                                @mouseleave="
                                                  mouseOver('edit_room_number')
                                                "
                                              >
                                                <v-list-item-title
                                                  v-html="data.item"
                                                  class="title-signup-sj"
                                                ></v-list-item-title>
                                              </v-list-item-content>
                                            </template>
                                          </v-select>
                                        </a>
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <div
                                    v-if="!manualCheckinValue"
                                    class="mt-5 d-flex flex-row align-center justify-center"
                                  >
                                    <button
                                      type="button"
                                      class="btn btn-primary btn-min-width mb-1 mr-1 font-14px"
                                      @click="saveEditRoomFunc"
                                    >
                                      {{ $t("SigninCalendar.btn_save_change") }}
                                    </button>
                                    <button
                                      type="button"
                                      class="btn btn-light btn-min-width mb-1 font-14px"
                                      @click="editRoomFunc"
                                    >
                                      {{ $t("SigninCalendar.btn_cancel") }}
                                    </button>
                                  </div>
                                  <div
                                    v-else-if="manualCheckinValue"
                                    class="mt-5 d-flex flex-row align-center justify-center"
                                  >
                                    <button
                                      type="button"
                                      class="btn btn-primary btn-min-width mb-1 font-14px"
                                      @click="editRoomFunc()"
                                    >
                                      {{ $t("SigninWeekend.dialog_close") }}
                                    </button>
                                  </div>
                                </div>
                              </v-menu>
                            </tr>
                            <tr :key="`${index}_2`">
                              <th
                                scope="row"
                                class="table-td-border"
                                style="
                                  width: 20rem;
                                  position: sticky;
                                  left: 0;
                                  background-color: #ffff;
                                "
                              >
                                <span class="text-h td-title">
                                  {{ $t("SigninCalendar.bulk_net_booked") }}
                                </span>
                              </th>

                              <v-menu
                                v-model="ele.showRoomSell"
                                absolute
                                offset-y
                                min-width="400"
                                style="border: none !important"
                                :close-on-content-click="closeOnShowRoomSell"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <td
                                    v-for="(el, i) in ele.room_dates"
                                    :key="`${index}_2${i}`"
                                    :id="`${index}_2${i}`"
                                    class="table-td-border bg-white text-right pr-3"
                                    :class="[
                                      el.date == headers[0] && 'border-now',
                                      (el.room_status == 'inactive' ||
                                        el.room_status == 'noinventory') &&
                                        'bg-table-red',
                                    ]"
                                    @click="
                                      [
                                        getfocusShowRoomSell(`${index}_2${i}`, attrs, on),
                                        showRoomSellFunc(el, i, ele, index),
                                      ]
                                    "
                                    @mouseleave="getfocusShowRoomSell(`${index}_2${i}`)"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    {{ el.room_number_use || 0 }}
                                  </td>
                                </template>
                                <div v-if="loadingShowRoomSellNumber">
                                  <v-skeleton-loader
                                    type="article, actions"
                                  ></v-skeleton-loader>
                                </div>
                                <div v-else class="bg-white pa-4">
                                  <div
                                    class="d-flex flex-row justify-space-between"
                                    :class="
                                      edit_room_number_remain <= 0
                                        ? 'align-start'
                                        : 'align-center'
                                    "
                                    style="width: 100%"
                                  >
                                    <div
                                      class="d-flex flex-column"
                                      :class="
                                        manualCheckinValue &&
                                        edit_room_number_remain <= 0 &&
                                        'mb-3'
                                      "
                                    >
                                      <h5 class="text-bold">
                                        {{ ele.default_room.room_name.value }}
                                      </h5>
                                      <small
                                        v-if="
                                          (edit_room_number_remain <= 0 ||
                                            edit_room_status == 'inactive') &&
                                          editRoomDates &&
                                          edit_room_number == editRoomDates.room_number
                                        "
                                        class="text-error"
                                      >
                                        ({{
                                          $t(
                                            edit_room_status == "inactive"
                                              ? "SigninCalendar.bulk_not_reserve_room"
                                              : "SigninCalendar.status_no_inventory"
                                          )
                                        }})
                                      </small>
                                    </div>
                                    <v-btn icon @click="showRoomSellFunc()">
                                      <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                  </div>
                                  <div
                                    class="d-flex flex-row align-center justify-space-between"
                                    style="width: 100%"
                                  >
                                    <h5 class="text-bold">{{ dateEditRoomText() }}</h5>
                                  </div>
                                  <v-radio-group
                                    row
                                    v-model="edit_room_status"
                                    :key="`${componentEditRoom}_${componentEditRoom}`"
                                    disabled
                                  >
                                    <v-radio
                                      :label="`${$t(
                                        'SigninCalendar.bulk_can_reserve_room'
                                      )}`"
                                      value="active"
                                      color="#1e9ff2"
                                      class="font-14px"
                                    ></v-radio>
                                    <v-radio
                                      :label="`${$t(
                                        'SigninCalendar.bulk_not_reserve_room'
                                      )}`"
                                      value="inactive"
                                      color="#1e9ff2"
                                      class="font-14px"
                                    ></v-radio>
                                    <v-radio
                                      :label="`${$t(
                                        'SigninCalendar.status_no_inventory'
                                      )}`"
                                      value="noinventory"
                                      color="#1e9ff2"
                                      class="font-14px"
                                    ></v-radio>
                                  </v-radio-group>
                                  <v-row v-if="editRoomDates" class="mt-3 align-center">
                                    <v-col cols="12" sm="12" md="6" lg="6">
                                      <label
                                        for="issueinput3"
                                        class="font-weight-bold font-14px"
                                      >
                                        {{ $t("SigninCalendar.bulk_room_tosell") }}
                                      </label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6">
                                      <label for="issueinput3" class="font-14px">
                                        {{ editRoomDates.room_number_remain || 0 }}
                                      </label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6">
                                      <label
                                        for="issueinput3"
                                        class="font-weight-bold font-14px"
                                      >
                                        {{ $t("SigninCalendar.bulk_net_booked") }}
                                      </label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6">
                                      <label for="issueinput3" class="font-14px">
                                        {{ editRoomDates.room_number_use || 0 }}
                                      </label>
                                    </v-col>
                                  </v-row>
                                  <div
                                    class="mt-5 d-flex flex-row align-center justify-center"
                                  >
                                    <button
                                      type="button"
                                      class="btn btn-primary btn-min-width mb-1 font-14px"
                                      @click="showRoomSellFunc()"
                                    >
                                      {{ $t("SigninWeekend.dialog_close") }}
                                    </button>
                                  </div>
                                </div>
                              </v-menu>
                            </tr>
                            <template v-for="(ee, i) in ele.rate_plans">
                              <tr :key="`${index}_3${i}`">
                                <th
                                  scope="row"
                                  class="table-td-border"
                                  style="
                                    width: 20rem;
                                    position: sticky;
                                    left: 0;
                                    background-color: #ffff;
                                  "
                                >
                                  <v-btn
                                    icon
                                    :disabled="!ee.haveoptions"
                                    @click="ee.showalloptions = !ee.showalloptions"
                                  >
                                    <v-icon class="font-blue-table-title">
                                      {{
                                        ee.showalloptions
                                          ? "mdi-chevron-up"
                                          : "mdi-chevron-down"
                                      }}
                                    </v-icon>
                                  </v-btn>
                                  <span class="text-h td-title font-blue-table-title">
                                    {{
                                      ee.default_rate_plan_price.name ||
                                      (ee.default_rate_plan_price
                                        .master_rate_plan_price &&
                                        ee.default_rate_plan_price.master_rate_plan_price
                                          .value) ||
                                      (ee.default_rate_plan_price.rate_plan &&
                                        ee.default_rate_plan_price.rate_plan.value) ||
                                      $t("SigninCalendar.bulk_room_name")
                                    }}
                                    <br />
                                    <span class="text-h td-title font-12px color-gray">{{
                                      $t("SigninCalendar.bulk_price")
                                    }}</span>
                                  </span>
                                </th>

                                <v-menu
                                  v-model="ee.showEditPrice"
                                  absolute
                                  offset-y
                                  min-width="400"
                                  style="border: none !important"
                                  :close-on-content-click="closeOnEditPrice"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <td
                                      v-for="(eel, ii) in ele.room_dates"
                                      :key="`${index}_3${i}_${ii}`"
                                      :id="`${index}_3${i}_${ii}`"
                                      class="table-td-border bg-white text-right"
                                      style="padding-right: 0.625rem"
                                      :class="[
                                        eel.date == headers[0] && 'border-now',
                                        manualCheckinValue && 'bg-table-red',
                                        eel.room_rateplans[i].default_rate_plan_price
                                          .master_rate_plan_price && 'bg-table-gray',
                                        eel.room_rateplans[i].status == 'inactive' ||
                                          (eel.room_rateplans[i].status ==
                                            'noinventory' &&
                                            'bg-table-red'),
                                        (eel.room_status == 'inactive' ||
                                          eel.room_status == 'noinventory') &&
                                          'bg-table-red',
                                      ]"
                                      @click="
                                        [
                                          !eel.room_rateplans[i].default_rate_plan_price
                                            .master_rate_plan_price &&
                                            getfocusEditPrice(
                                              `${index}_3${i}_${ii}`,
                                              attrs,
                                              on
                                            ),
                                          !eel.room_rateplans[i].default_rate_plan_price
                                            .master_rate_plan_price &&
                                            editPriceFunc(eel, ii, ee, i, ele, false),
                                          eel.room_rateplans[i].default_rate_plan_price
                                            .master_rate_plan_price &&
                                            getfocusShowPriceDetail(
                                              `${index}_3${i}_${ii}`,
                                              attrs,
                                              on
                                            ),
                                          eel.room_rateplans[i].default_rate_plan_price
                                            .master_rate_plan_price &&
                                            showPriceDetailFunc(eel, ii, ee, i, ele),
                                        ]
                                      "
                                      @mouseleave="
                                        [
                                          !eel.room_rateplans[i].default_rate_plan_price
                                            .master_rate_plan_price &&
                                            getfocusEditPrice(`${index}_3${i}_${ii}`),
                                          eel.room_rateplans[i].default_rate_plan_price
                                            .master_rate_plan_price &&
                                            getfocusShowPriceDetail(
                                              `${index}_3${i}_${ii}`
                                            ),
                                        ]
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <small>THB</small>
                                      <br />
                                      <small>
                                        {{
                                          checkRoomRatePlan(eel, ii, ee, i, ele, index)
                                        }}
                                      </small>
                                    </td>
                                  </template>

                                  <div class="bg-white pa-4" style>
                                    <div
                                      class="d-flex flex-row align-start justify-space-between"
                                      style="width: 100%"
                                    >
                                      <div class="d-flex flex-column">
                                        <h5 class="text-bold">
                                          {{
                                            ele.default_room.room_name.value ||
                                            ee.default_rate_plan_price.name ||
                                            (ee.default_rate_plan_price
                                              .master_rate_plan_price &&
                                              ee.default_rate_plan_price
                                                .master_rate_plan_price.value) ||
                                            (ee.default_rate_plan_price.rate_plan &&
                                              ee.default_rate_plan_price.rate_plan.value)
                                          }}
                                        </h5>
                                        <small class="text-dark">
                                          ({{
                                            ee.default_rate_plan_price.name ||
                                            (ee.default_rate_plan_price
                                              .master_rate_plan_price &&
                                              ee.default_rate_plan_price
                                                .master_rate_plan_price.value) ||
                                            (ee.default_rate_plan_price.rate_plan &&
                                              ee.default_rate_plan_price.rate_plan.value)
                                          }})
                                        </small>
                                      </div>
                                      <v-btn
                                        icon
                                        @click="
                                          editPriceRatePlan &&
                                          !editPriceRatePlan.default_rate_plan_price
                                            .master_rate_plan_price
                                            ? editPriceFunc()
                                            : showPriceDetailFunc()
                                        "
                                      >
                                        <v-icon>mdi-close</v-icon>
                                      </v-btn>
                                    </div>
                                    <div
                                      class="d-flex flex-row align-center justify-space-between"
                                      style="width: 100%"
                                    >
                                      <h5 class="text-bold">
                                        {{ dateEditPriceText(ee) }}
                                      </h5>
                                      <v-btn
                                        v-if="
                                          editPriceRatePlan &&
                                          !editPriceRatePlan.default_rate_plan_price
                                            .master_rate_plan_price &&
                                          !manualCheckinValue
                                        "
                                        class="btn btn-warning btn-min-width my-2"
                                        depressed
                                        dark
                                        @click="
                                          [editPriceFunc(), toggleBulkEditMenu(ele)]
                                        "
                                      >
                                        {{ $t("SigninCalendar.btn_bulk_edit") }}
                                      </v-btn>
                                    </div>
                                    <v-radio-group
                                      :disabled="
                                        (editPriceRatePlan &&
                                          editPriceRatePlan.default_rate_plan_price
                                            .master_rate_plan_price) ||
                                        manualCheckinValue
                                      "
                                      row
                                      v-model="edit_price_active"
                                    >
                                      <v-radio
                                        :label="`${$t(
                                          'SigninCalendar.bulk_can_reserve_room'
                                        )}`"
                                        value="active"
                                        color="#1e9ff2"
                                        class="font-14px"
                                      ></v-radio>
                                      <v-radio
                                        :label="`${$t(
                                          'SigninCalendar.bulk_not_reserve_room'
                                        )}`"
                                        value="inactive"
                                        color="#1e9ff2"
                                        class="font-14px"
                                      ></v-radio>
                                      <v-radio
                                        :label="`${$t(
                                          'SigninCalendar.status_no_inventory'
                                        )}`"
                                        value="noinventory"
                                        color="#1e9ff2"
                                        class="font-14px"
                                        disabled
                                      ></v-radio>
                                    </v-radio-group>
                                    <v-row class="mt-3 align-center">
                                      <v-col
                                        v-if="ee.default_rate_plan_price.per_room"
                                        cols="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                      >
                                        <label
                                          for="issueinput3"
                                          class="font-weight-bold font-14px"
                                        >
                                          {{ $t("SigninCalendar.bulk_room_price") }}
                                        </label>
                                      </v-col>

                                      <v-col
                                        v-if="ee.default_rate_plan_price.per_room"
                                        cols="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                      >
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <div class="input-group-text font-14px">
                                              {{ $t("SigninCalendar.bulk_price_unit") }}
                                            </div>
                                          </div>
                                          <input
                                            type="number"
                                            min="0"
                                            @keypress="isNumber($event)"
                                            class="form-control boder-prepend-left font-14px"
                                            id="edit_price_base_rate"
                                            placeholder
                                            v-model="edit_price_base_rate"
                                            @focus="getfocus('edit_price_base_rate')"
                                            @change="getfocus('edit_price_base_rate')"
                                            @mouseleave="
                                              mouseOver('edit_price_base_rate')
                                            "
                                            :disabled="
                                              editPriceRatePlan &&
                                              editPriceRatePlan.default_rate_plan_price
                                                .master_rate_plan_price
                                            "
                                          />
                                        </div>
                                      </v-col>

                                      <v-col
                                        v-if="ee.default_rate_plan_price.per_room"
                                        cols="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                      >
                                        <label
                                          for="issueinput3"
                                          class="font-weight-bold font-14px"
                                        >
                                          {{ $t("SigninCalendar.bulk_extra_bed_adult") }}
                                        </label>
                                      </v-col>
                                      <v-col
                                        v-if="ee.default_rate_plan_price.per_room"
                                        cols="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                      >
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <div class="input-group-text font-14px">
                                              {{ $t("SigninCalendar.bulk_price_unit") }}
                                            </div>
                                          </div>
                                          <input
                                            type="number"
                                            min="0"
                                            @keypress="isNumber($event)"
                                            class="form-control boder-prepend-left font-14px"
                                            id="edit_price_extra_bed_adult"
                                            placeholder
                                            v-model="edit_price_extra_bed_adult"
                                            @focus="
                                              getfocus('edit_price_extra_bed_adult')
                                            "
                                            @change="
                                              getfocus('edit_price_extra_bed_adult')
                                            "
                                            @mouseleave="
                                              mouseOver('edit_price_extra_bed_adult')
                                            "
                                            :disabled="
                                              editPriceRatePlan &&
                                              (editPriceRatePlan.default_rate_plan_price
                                                .master_rate_plan_price ||
                                                (!editPriceRatePlan
                                                  .default_rate_plan_price
                                                  .master_rate_plan_price &&
                                                  editPriceItems.room_customer_normal >=
                                                    editPriceItems.room_customer_max))
                                            "
                                          />
                                        </div>
                                      </v-col>

                                      <v-col
                                        v-if="ee.default_rate_plan_price.per_room"
                                        cols="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                      >
                                        <label
                                          for="issueinput3"
                                          class="font-weight-bold font-14px"
                                        >
                                          {{ $t("SigninCalendar.bulk_extra_bed_child") }}
                                        </label>
                                      </v-col>
                                      <v-col
                                        v-if="ee.default_rate_plan_price.per_room"
                                        cols="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                      >
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <div class="input-group-text font-14px">
                                              {{ $t("SigninCalendar.bulk_price_unit") }}
                                            </div>
                                          </div>
                                          <input
                                            type="text"
                                            class="form-control boder-prepend-left font-14px"
                                            id="edit_price_extra_bed_child"
                                            placeholder
                                            v-model="edit_price_extra_bed_child"
                                            @focus="
                                              getfocus('edit_price_extra_bed_child')
                                            "
                                            @change="
                                              getfocus('edit_price_extra_bed_child')
                                            "
                                            @mouseleave="
                                              mouseOver('edit_price_extra_bed_child')
                                            "
                                            :disabled="
                                              editPriceRatePlan &&
                                              (editPriceRatePlan.default_rate_plan_price
                                                .master_rate_plan_price ||
                                                (!editPriceRatePlan
                                                  .default_rate_plan_price
                                                  .master_rate_plan_price &&
                                                  editPriceItems.room_customer_normal >=
                                                    editPriceItems.room_customer_max))
                                            "
                                          />
                                        </div>
                                      </v-col>

                                      <v-col
                                        v-if="ee.default_rate_plan_price.per_guest"
                                        cols="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                      >
                                        <label
                                          for="issueinput3"
                                          class="font-weight-bold font-14px"
                                          >{{ $t("SignupStep3.adultrate") }}</label
                                        >
                                      </v-col>
                                      <v-col
                                        v-if="ee.default_rate_plan_price.per_guest"
                                        cols="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                      >
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <div class="input-group-text font-14px">
                                              {{ $t("SigninCalendar.bulk_price_unit") }}
                                            </div>
                                          </div>
                                          <input
                                            type="number"
                                            min="0"
                                            @keypress="isNumber($event)"
                                            class="form-control boder-prepend-left font-14px"
                                            id="edit_price_adult_rate"
                                            placeholder
                                            v-model="edit_price_adult_rate"
                                            @focus="getfocus('edit_price_adult_rate')"
                                            @change="getfocus('edit_price_adult_rate')"
                                            @mouseleave="
                                              mouseOver('edit_price_adult_rate')
                                            "
                                            :disabled="
                                              editPriceRatePlan &&
                                              editPriceRatePlan.default_rate_plan_price
                                                .master_rate_plan_price
                                            "
                                          />
                                        </div>
                                      </v-col>

                                      <v-col
                                        v-if="ee.default_rate_plan_price.per_guest"
                                        cols="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                      >
                                        <label
                                          for="issueinput3"
                                          class="font-weight-bold font-14px"
                                          >{{ $t("SignupStep3.childrate") }}</label
                                        >
                                      </v-col>
                                      <v-col
                                        v-if="ee.default_rate_plan_price.per_guest"
                                        cols="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                      >
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <div class="input-group-text font-14px">
                                              {{ $t("SigninCalendar.bulk_price_unit") }}
                                            </div>
                                          </div>
                                          <input
                                            type="number"
                                            min="0"
                                            @keypress="isNumber($event)"
                                            class="form-control boder-prepend-left font-14px"
                                            id="edit_price_child_rate"
                                            placeholder
                                            v-model="edit_price_child_rate"
                                            @focus="getfocus('edit_price_child_rate')"
                                            @change="getfocus('edit_price_child_rate')"
                                            @mouseleave="
                                              mouseOver('edit_price_child_rate')
                                            "
                                            :disabled="
                                              editPriceRatePlan &&
                                              editPriceRatePlan.default_rate_plan_price
                                                .master_rate_plan_price
                                            "
                                          />
                                        </div>
                                      </v-col>

                                      <v-col
                                        v-if="edit_price_package_items.length"
                                        cols="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                      >
                                        <p class="font-weight-bold font-14px">
                                          {{ $t("SigninCalendar.bulk_package") }}
                                        </p>
                                      </v-col>
                                      <v-row
                                        v-for="item in edit_price_package_items"
                                        :key="item.activity"
                                        class="mx-0 mb-0"
                                      >
                                        <v-col
                                          v-if="edit_price_package_items.length"
                                          cols="12"
                                          sm="12"
                                          md="12"
                                          lg="12"
                                          class="py-0"
                                        >
                                          <label
                                            for="issueinput3"
                                            class="font-weight-bold font-14px"
                                          >
                                            {{ checkNameActivity(item.activity) }}
                                          </label>
                                        </v-col>
                                        <v-col
                                          v-if="
                                            edit_price_package_items.length &&
                                            item.unit == 'voucher'
                                          "
                                          cols="12"
                                          sm="12"
                                          md="4"
                                          lg="4"
                                          class="py-0"
                                        >
                                          <label
                                            for="issueinput3"
                                            class="font-weight-bold font-14px mb-1"
                                            >{{ $t("SignupStep3.baserate") }}</label
                                          >
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <div class="input-group-text font-14px">
                                                {{ $t("SigninCalendar.bulk_price_unit") }}
                                              </div>
                                            </div>
                                            <input
                                              type="number"
                                              min="0"
                                              @keypress="isNumber($event)"
                                              class="form-control boder-prepend-left font-14px"
                                              :id="`${item.activity}_base_rate`"
                                              placeholder
                                              v-model="item.base_rate"
                                              @focus="
                                                getfocus(`${item.activity}_base_rate`)
                                              "
                                              @change="
                                                getfocus(`${item.activity}_base_rate`)
                                              "
                                              @mouseleave="
                                                mouseOver(`${item.activity}_base_rate`)
                                              "
                                              :disabled="
                                                editPriceRatePlan &&
                                                editPriceRatePlan.default_rate_plan_price
                                                  .master_rate_plan_price
                                              "
                                            />
                                          </div>
                                        </v-col>
                                        <v-col
                                          v-if="
                                            edit_price_package_items.length &&
                                            item.unit == 'voucher'
                                          "
                                          cols="12"
                                          sm="12"
                                          md="4"
                                          lg="4"
                                          class="py-0"
                                        >
                                          <label
                                            for="issueinput3"
                                            class="font-weight-bold font-14px mb-1"
                                          >
                                            {{ $t("SignupStep3.extrabed")
                                            }}{{ $t("SignupStep3.adult") }}
                                          </label>
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <div class="input-group-text font-14px">
                                                {{ $t("SigninCalendar.bulk_price_unit") }}
                                              </div>
                                            </div>
                                            <input
                                              type="number"
                                              min="0"
                                              @keypress="isNumber($event)"
                                              class="form-control boder-prepend-left font-14px"
                                              :id="`${item.activity}_extra_bed_adult_rate`"
                                              placeholder
                                              v-model="item.extra_bed_adult"
                                              @focus="
                                                getfocus(
                                                  `${item.activity}_extra_bed_adult_rate`
                                                )
                                              "
                                              @change="
                                                getfocus(
                                                  `${item.activity}_extra_bed_adult_rate`
                                                )
                                              "
                                              @mouseleave="
                                                mouseOver(
                                                  `${item.activity}_extra_bed_adult_rate`
                                                )
                                              "
                                              :disabled="
                                                editPriceRatePlan &&
                                                editPriceRatePlan.default_rate_plan_price
                                                  .master_rate_plan_price
                                              "
                                            />
                                          </div>
                                        </v-col>
                                        <v-col
                                          v-if="
                                            edit_price_package_items.length &&
                                            item.unit == 'voucher'
                                          "
                                          cols="12"
                                          sm="12"
                                          md="4"
                                          lg="4"
                                          class="py-0"
                                        >
                                          <label
                                            for="issueinput3"
                                            class="font-weight-bold font-14px mb-1"
                                          >
                                            {{ $t("SignupStep3.extrabed")
                                            }}{{ $t("SignupStep3.child") }}
                                          </label>
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <div class="input-group-text font-14px">
                                                {{ $t("SigninCalendar.bulk_price_unit") }}
                                              </div>
                                            </div>
                                            <input
                                              type="number"
                                              min="0"
                                              @keypress="isNumber($event)"
                                              class="form-control boder-prepend-left font-14px"
                                              :id="`${item.activity}_extra_bed_child_rate`"
                                              placeholder
                                              v-model="item.extra_bed_child"
                                              @focus="
                                                getfocus(
                                                  `${item.activity}_extra_bed_child_rate`
                                                )
                                              "
                                              @change="
                                                getfocus(
                                                  `${item.activity}_extra_bed_child_rate`
                                                )
                                              "
                                              @mouseleave="
                                                mouseOver(
                                                  `${item.activity}_extra_bed_child_rate`
                                                )
                                              "
                                              :disabled="
                                                editPriceRatePlan &&
                                                editPriceRatePlan.default_rate_plan_price
                                                  .master_rate_plan_price
                                              "
                                            />
                                          </div>
                                        </v-col>

                                        <v-col
                                          v-if="
                                            edit_price_package_items.length &&
                                            item.unit == 'person'
                                          "
                                          cols="12"
                                          sm="12"
                                          md="6"
                                          lg="6"
                                          class="py-0"
                                        >
                                          <label
                                            for="issueinput3"
                                            class="font-weight-bold font-14px mb-1"
                                            >{{ $t("SignupStep3.adult") }}</label
                                          >
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <div class="input-group-text font-14px">
                                                {{ $t("SigninCalendar.bulk_price_unit") }}
                                              </div>
                                            </div>
                                            <input
                                              type="number"
                                              min="0"
                                              @keypress="isNumber($event)"
                                              class="form-control boder-prepend-left font-14px"
                                              :id="`${item.activity}_adult_rate`"
                                              placeholder
                                              v-model="item.adult_rate"
                                              @focus="
                                                getfocus(`${item.activity}_adult_rate`)
                                              "
                                              @change="
                                                getfocus(`${item.activity}_adult_rate`)
                                              "
                                              @mouseleave="
                                                mouseOver(`${item.activity}_adult_rate`)
                                              "
                                              :disabled="
                                                editPriceRatePlan &&
                                                editPriceRatePlan.default_rate_plan_price
                                                  .master_rate_plan_price
                                              "
                                            />
                                          </div>
                                        </v-col>
                                        <v-col
                                          v-if="
                                            edit_price_package_items.length &&
                                            item.unit == 'person'
                                          "
                                          cols="12"
                                          sm="12"
                                          md="6"
                                          lg="6"
                                          class="py-0"
                                        >
                                          <label
                                            for="issueinput3"
                                            class="font-weight-bold font-14px mb-1"
                                            >{{ $t("SignupStep3.child") }}</label
                                          >
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <div class="input-group-text font-14px">
                                                {{ $t("SigninCalendar.bulk_price_unit") }}
                                              </div>
                                            </div>
                                            <input
                                              type="number"
                                              min="0"
                                              @keypress="isNumber($event)"
                                              class="form-control boder-prepend-left font-14px"
                                              :id="`${item.activity}_child_rate`"
                                              placeholder
                                              v-model="item.child_rate"
                                              @focus="
                                                getfocus(`${item.activity}_child_rate`)
                                              "
                                              @change="
                                                getfocus(`${item.activity}_child_rate`)
                                              "
                                              @mouseleave="
                                                mouseOver(`${item.activity}_child_rate`)
                                              "
                                              :disabled="
                                                editPriceRatePlan &&
                                                editPriceRatePlan.default_rate_plan_price
                                                  .master_rate_plan_price
                                              "
                                            />
                                          </div>
                                        </v-col>
                                      </v-row>
                                    </v-row>
                                    <div
                                      v-if="
                                        editPriceRatePlan &&
                                        !editPriceRatePlan.default_rate_plan_price
                                          .master_rate_plan_price
                                      "
                                      class="mt-5 d-flex flex-row align-center justify-center"
                                      :class="edit_price_package_items.length && 'mt-8'"
                                    >
                                      <button
                                        type="button"
                                        class="btn btn-primary btn-min-width mb-1 mr-1 font-14px"
                                        @click="saveEditPriceFunc()"
                                      >
                                        {{ $t("SigninCalendar.btn_save_change") }}
                                      </button>
                                      <button
                                        type="button"
                                        class="btn btn-light btn-min-width mb-1 font-14px"
                                        @click="editPriceFunc()"
                                      >
                                        {{ $t("SigninCalendar.btn_cancel") }}
                                      </button>
                                    </div>

                                    <div
                                      v-else-if="
                                        editPriceRatePlan &&
                                        editPriceRatePlan.default_rate_plan_price
                                          .master_rate_plan_price
                                      "
                                      class="mt-5 d-flex flex-row align-center justify-center"
                                      :class="edit_price_package_items.length && 'mt-8'"
                                    >
                                      <button
                                        type="button"
                                        class="btn btn-primary btn-min-width mb-1 font-14px"
                                        @click="showPriceDetailFunc()"
                                      >
                                        {{ $t("SigninWeekend.dialog_close") }}
                                      </button>
                                    </div>
                                  </div>
                                </v-menu>
                              </tr>

                              <tr
                                :key="`${index}_4${i}`"
                                v-if="
                                  (ee.showalloptions && ee.haverateperperson) ||
                                  ee.showrateperperson
                                "
                              >
                                <th
                                  scope="row"
                                  class="table-td-border text-h pl-0"
                                  style="
                                    width: 20rem;
                                    position: sticky;
                                    left: 0;
                                    background-color: #e2ebfc !important;
                                    padding: 4px;
                                  "
                                >
                                  <span class="text-h blue-table-title-list-show">
                                    {{ $t("SigninCalendar.single_use_per_night") }}
                                  </span>
                                </th>

                                <td
                                  v-for="(eel, ii) in ele.room_dates_status"
                                  :key="`${index}_4${i}_${ii}`"
                                  :id="`${index}_4${i}_${ii}`"
                                  class="table-td-border bg-white text-right"
                                  style="
                                    padding-right: 0.625rem;
                                    background-color: #e2ebfc !important;
                                  "
                                  :class="[
                                    ii == 0 && 'border-now',
                                    eel.room_rateplans[i].default_rate_plan_price
                                      .master_rate_plan_price && 'bg-table-gray',
                                    eel.room_rateplans[i].status == 'inactive' &&
                                      'bg-table-red',
                                    eel.room_status == 'inactive' && 'bg-table-red',
                                  ]"
                                >
                                  <span style="color: #1f69ff !important">
                                    <small>THB</small>
                                    <br />
                                    <small>
                                      {{
                                        checkRoomRatePlanPerNight(
                                          eel,
                                          ii,
                                          ee,
                                          i,
                                          ele,
                                          index
                                        )
                                      }}
                                    </small>
                                  </span>
                                </td>
                              </tr>

                              <tr
                                :key="`${index}_5${i}`"
                                v-if="
                                  ee.default_rate_plan_price.minimum_stay > 1 &&
                                  ((ee.showalloptions && ee.haveminimumstay) ||
                                    ee.showminimumstay)
                                "
                              >
                                <th
                                  scope="row"
                                  class="table-td-border text-h pl-0"
                                  style="
                                    width: 20rem;
                                    position: sticky;
                                    left: 0;
                                    background-color: #e2ebfc !important;
                                    padding: 4px;
                                  "
                                >
                                  <span class="text-h blue-table-title-list-show">
                                    {{ $t("SigninCalendar.bulk_min_stay") }}
                                  </span>
                                </th>

                                <td
                                  v-for="(eel, ii) in ele.room_dates_status"
                                  :key="`${index}_5${i}_${ii}`"
                                  :id="`${index}_5${i}_${ii}`"
                                  class="table-td-border bg-white text-right pr-3"
                                  style="
                                    padding-right: 0.625rem;
                                    background-color: #e2ebfc !important;
                                  "
                                  :class="ii == 0 && 'border-now'"
                                >
                                  <span style="color: #1f69ff !important">
                                    <small>
                                      {{
                                        checkRoomRatePlanMinimumStay(
                                          eel,
                                          ii,
                                          ee,
                                          i,
                                          ele,
                                          index
                                        )
                                      }}
                                    </small>
                                  </span>
                                </td>
                              </tr>
                            </template>
                          </template>
                        </tbody>
                      </table>

                      <div
                        v-if="!overlay && !itemsCalendar.length"
                        class="my-10 d-flex align-center justify-center"
                        style="
                          width: 100% !important;
                          position: sticky;
                          top: 50%;
                          left: 0;
                        "
                      >
                        <span class="text-gray">
                          {{ $t("RateAndInventory.nodata") }}
                        </span>
                      </div>

                      <div
                        v-if="overlay && !itemsCalendar.length"
                        class="my-10 d-flex align-center justify-center"
                        style="width: 100% !important"
                      >
                        <!-- <img
                            :src="require('@/assets/img/thumb/loading.gif')"
                            class="img-gif-loading"
                        />-->
                        <v-skeleton-loader
                          style="width: 100%"
                          type="table-tbody"
                        ></v-skeleton-loader>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-container>
      </div>
    </v-main>

    <v-navigation-drawer
      v-model="isSideBarOpen"
      absolute
      temporary
      right
      :width="widthSideBar"
      style="position: fixed"
    >
      <v-list-item class="mt-3">
        <div
          class="d-flex flex-row align-center justify-space-between"
          style="width: 100%"
        >
          <h4 class="text-uppercase mb-0">
            {{ bulk_room_name || $t("SigninCalendar.bulk_room_name") }}
          </h4>
          <v-btn icon @click="toggleBulkEditMenu(bulk_item)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-list-item>

      <v-divider class="mt-1 mx-3"></v-divider>

      <v-row class="mx-1 mb-5" style="overflow-y: auto !important">
        <v-col cols="12" sm="12" md="6" lg="6">
          <div class="form-group">
            <label for="issueinput3" class="font-weight-bold font-14px mb-2">{{
              $t("SigninCalendar.bulk_date_start")
            }}</label>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <input
                  type="date"
                  id="bulk_datestart"
                  class="form-control input-date-select font-14px"
                  name="bulk_datestart"
                  title
                  v-model="bulk_date_start"
                  :min="filter_select_daterange[0]"
                  :max="filter_select_daterange[1]"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>{{ $t("SigninCalendar.bulk_date_opened") }}</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <div class="form-group">
            <label for="issueinput4" class="font-weight-bold font-14px mb-2">{{
              $t("SigninCalendar.bulk_date_end")
            }}</label>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <input
                  type="date"
                  id="bulk_dateend"
                  class="form-control input-date-select font-14px"
                  name="bulk_dateend"
                  title
                  v-model="bulk_date_end"
                  :min="bulk_date_start || filter_select_daterange[0]"
                  :max="bulkMaxDate"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>{{ $t("SigninCalendar.bulk_date_fixed") }}</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" class="mb-13">
          <p class="font-weight-bold font-14px">
            {{ $t("SigninCalendar.bulk_date_change") }}
          </p>
          <div class="day-show flex-wrap d-flex">
            <div
              class="custom-control custom-checkbox mr-3"
              v-for="item in bulk_itemsDays"
              :key="item.value"
            >
              <input
                :id="item.value"
                name="days"
                type="checkbox"
                class="custom-control-input font-14px"
                :value="item._id"
                :checked="item.check"
                @change="checkListDays(item)"
              />
              <label class="custom-control-label font-14px" :for="item.value">{{
                item.name
              }}</label>
            </div>

            <ul id="accordion" class="accordion card-accordion">
              <v-expansion-panels accordion flat>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="link font-weight-bold pa-0 pt-3 pr-3">
                      {{ $t("SigninCalendar.bulk_booknum_room") }}
                      <br />
                      <small>{{ $t("SigninCalendar.bulk_update_booknum_room") }}</small>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6">
                        <div class="form-group">
                          <label for="issueinput3" class="font-weight-bold font-14px">{{
                            $t("SigninCalendar.bulk_salenum_room")
                          }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6">
                        <div class="form-group">
                          <div class="input-group mt-0">
                            <input
                              type="number"
                              min="0"
                              @keypress="isNumber($event)"
                              class="form-control boder-prepend-right input-price-style font-14px"
                              placeholder
                              aria-label
                              name="rateperhour"
                              v-model="bulk_salenum_room"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text font-14px">{{
                                $t("SigninCalendar.bulk_room_unit")
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <small>
                          {{ $t("SigninCalendar.bulk_apply_change_daterange") }}:
                          {{ bulk_date_start }}
                          {{ $t("SigninTeamPurchase.level_to") }}
                          {{ bulk_date_end }}
                        </small>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="12" class="d-flex flex-row">
                        <button
                          type="button"
                          class="btn btn-primary btn-min-width mb-1 mr-1 font-14px"
                          @click="saveChangeBulk"
                        >
                          {{ $t("SigninCalendar.btn_save_change") }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-light btn-min-width mb-1 font-14px"
                          @click="toggleBulkEditMenu"
                        >
                          {{ $t("SigninCalendar.btn_cancel") }}
                        </button>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                  <v-divider />
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="link font-weight-bold pa-0 pr-3">
                      {{ $t("SigninCalendar.bulk_price") }}
                      <br />
                      <small>{{ $t("SigninCalendar.bulk_adjust_rateprice") }}</small>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      class="dropdown select"
                      @click="getfocus('bulk_rateplan')"
                      @mouseleave="mouseOver('bulk_rateplan')"
                    >
                      <a id="bulk_rateplan" style="z-index: 99999">
                        <v-select
                          v-model="bulk_rateplan"
                          :items="bulk_rateplan_items"
                          item-text="name"
                          item-value="value"
                          label
                          :placeholder="$t('SigninCalendar.bulk_select_rateplan')"
                          :no-data-text="$t('SignupStep.nodata')"
                          color="#1e9ff2"
                          class="font-14px"
                          solo
                          flat
                          dense
                          single-line
                          hide-details
                          @change="selectBulkRateplan"
                        >
                          <template v-slot:item="data">
                            <v-list-item-content
                              @mouseenter="getfocus('bulk_rateplan')"
                              @mouseleave="mouseOver('bulk_rateplan')"
                            >
                              <v-list-item-title
                                v-html="data.item.name"
                                class="title-signup-sj font-14px"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </a>
                    </div>
                    <v-row class="mt-3">
                      <v-col v-if="bulk_per_room" cols="12" sm="12" md="6" lg="6">
                        <label for="issueinput3" class="font-weight-bold font-14px">{{
                          $t("SigninCalendar.bulk_room_price")
                        }}</label>
                      </v-col>
                      <v-col v-if="bulk_per_room" cols="12" sm="12" md="6" lg="6">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text font-14px">
                              {{ $t("SigninCalendar.bulk_price_unit") }}
                            </div>
                          </div>
                          <input
                            type="number"
                            min="0"
                            @keypress="isNumber($event)"
                            class="form-control boder-prepend-left font-14px"
                            id="bulk_base_rate"
                            placeholder
                            v-model="bulk_base_rate"
                            :disabled="!bulk_rateplan"
                            @focus="getfocus('bulk_base_rate')"
                            @change="getfocus('bulk_base_rate')"
                            @mouseleave="mouseOver('bulk_base_rate')"
                          />
                        </div>
                      </v-col>

                      <v-col v-if="bulk_per_room" cols="12" sm="12" md="6" lg="6">
                        <label for="issueinput3" class="font-weight-bold font-14px">{{
                          $t("SigninCalendar.bulk_extra_bed_adult")
                        }}</label>
                      </v-col>
                      <v-col v-if="bulk_per_room" cols="12" sm="12" md="6" lg="6">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text font-14px">
                              {{ $t("SigninCalendar.bulk_price_unit") }}
                            </div>
                          </div>
                          <input
                            type="number"
                            min="0"
                            @keypress="isNumber($event)"
                            class="form-control boder-prepend-left font-14px"
                            id="bulk_extra_bed_adult"
                            placeholder
                            v-model="bulk_extra_bed_adult"
                            :disabled="
                              !bulk_rateplan ||
                              bulk_room_detail_property.customer.normal >=
                                bulk_room_detail_property.customer.max
                            "
                            @focus="getfocus('bulk_extra_bed_adult')"
                            @change="getfocus('bulk_extra_bed_adult')"
                            @mouseleave="mouseOver('bulk_extra_bed_adult')"
                          />
                        </div>
                      </v-col>

                      <v-col v-if="bulk_per_room" cols="12" sm="12" md="6" lg="6">
                        <label for="issueinput3" class="font-weight-bold font-14px">{{
                          $t("SigninCalendar.bulk_extra_bed_child")
                        }}</label>
                      </v-col>
                      <v-col v-if="bulk_per_room" cols="12" sm="12" md="6" lg="6">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text font-14px">
                              {{ $t("SigninCalendar.bulk_price_unit") }}
                            </div>
                          </div>
                          <input
                            type="text"
                            class="form-control boder-prepend-left font-14px"
                            id="bulk_extra_bed_child"
                            placeholder
                            v-model="bulk_extra_bed_child"
                            :disabled="
                              !bulk_rateplan ||
                              bulk_room_detail_property.customer.normal >=
                                bulk_room_detail_property.customer.max
                            "
                            @focus="getfocus('bulk_extra_bed_child')"
                            @change="getfocus('bulk_extra_bed_child')"
                            @mouseleave="mouseOver('bulk_extra_bed_child')"
                          />
                        </div>
                      </v-col>

                      <v-col v-if="bulk_per_guest" cols="12" sm="12" md="6" lg="6">
                        <label for="issueinput3" class="font-weight-bold font-14px">{{
                          $t("SignupStep3.adultrate")
                        }}</label>
                      </v-col>
                      <v-col v-if="bulk_per_guest" cols="12" sm="12" md="6" lg="6">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text font-14px">
                              {{ $t("SigninCalendar.bulk_price_unit") }}
                            </div>
                          </div>
                          <input
                            type="number"
                            min="0"
                            @keypress="isNumber($event)"
                            class="form-control boder-prepend-left font-14px"
                            id="bulk_adult_rate"
                            placeholder
                            v-model="bulk_adult_rate"
                            :disabled="!bulk_rateplan"
                            @focus="getfocus('bulk_adult_rate')"
                            @change="getfocus('bulk_adult_rate')"
                            @mouseleave="mouseOver('bulk_adult_rate')"
                          />
                        </div>
                      </v-col>

                      <v-col v-if="bulk_per_guest" cols="12" sm="12" md="6" lg="6">
                        <label for="issueinput3" class="font-weight-bold font-14px">{{
                          $t("SignupStep3.childrate")
                        }}</label>
                      </v-col>
                      <v-col v-if="bulk_per_guest" cols="12" sm="12" md="6" lg="6">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text font-14px">
                              {{ $t("SigninCalendar.bulk_price_unit") }}
                            </div>
                          </div>
                          <input
                            type="number"
                            min="0"
                            @keypress="isNumber($event)"
                            class="form-control boder-prepend-left font-14px"
                            id="bulk_child_rate"
                            placeholder
                            v-model="bulk_child_rate"
                            :disabled="!bulk_rateplan"
                            @focus="getfocus('bulk_child_rate')"
                            @change="getfocus('bulk_child_rate')"
                            @mouseleave="mouseOver('bulk_child_rate')"
                          />
                        </div>
                      </v-col>

                      <v-col
                        v-if="bulk_package_items.length"
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                      >
                        <p class="font-weight-bold font-14px">
                          {{ $t("SigninCalendar.bulk_package") }}
                        </p>
                      </v-col>
                      <v-row
                        v-for="item in bulk_package_items"
                        :key="item.activity"
                        class="mx-0"
                      >
                        <v-col
                          v-if="bulk_package_items.length"
                          cols="12"
                          sm="12"
                          md="12"
                          lg="12"
                        >
                          <label for="issueinput3" class="font-weight-bold font-14px">{{
                            checkNameActivity(item.activity)
                          }}</label>
                        </v-col>
                        <v-col
                          v-if="bulk_package_items.length && item.unit == 'voucher'"
                          cols="12"
                          sm="12"
                          md="4"
                          lg="4"
                          class="py-0"
                        >
                          <label
                            for="issueinput3"
                            class="font-weight-bold font-14px mb-1"
                            >{{ $t("SignupStep3.baserate") }}</label
                          >
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text font-14px">
                                {{ $t("SigninCalendar.bulk_price_unit") }}
                              </div>
                            </div>
                            <input
                              type="number"
                              min="0"
                              @keypress="isNumber($event)"
                              class="form-control boder-prepend-left font-14px"
                              :id="`${item.activity}_base_rate`"
                              placeholder
                              v-model="item.base_rate"
                              @focus="getfocus(`${item.activity}_base_rate`)"
                              @change="getfocus(`${item.activity}_base_rate`)"
                              @mouseleave="mouseOver(`${item.activity}_base_rate`)"
                            />
                          </div>
                        </v-col>
                        <v-col
                          v-if="bulk_package_items.length && item.unit == 'voucher'"
                          cols="12"
                          sm="12"
                          md="4"
                          lg="4"
                          class="py-0"
                        >
                          <label
                            for="issueinput3"
                            class="font-weight-bold font-14px mb-1"
                          >
                            {{ $t("SignupStep3.extrabed") }}{{ $t("SignupStep3.adult") }}
                          </label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text font-14px">
                                {{ $t("SigninCalendar.bulk_price_unit") }}
                              </div>
                            </div>
                            <input
                              type="number"
                              min="0"
                              @keypress="isNumber($event)"
                              class="form-control boder-prepend-left font-14px"
                              :id="`${item.activity}_extra_bed_adult_rate`"
                              placeholder
                              v-model="item.extra_bed_adult"
                              @focus="getfocus(`${item.activity}_extra_bed_adult_rate`)"
                              @change="getfocus(`${item.activity}_extra_bed_adult_rate`)"
                              @mouseleave="
                                mouseOver(`${item.activity}_extra_bed_adult_rate`)
                              "
                            />
                          </div>
                        </v-col>
                        <v-col
                          v-if="bulk_package_items.length && item.unit == 'voucher'"
                          cols="12"
                          sm="12"
                          md="4"
                          lg="4"
                          class="py-0"
                        >
                          <label
                            for="issueinput3"
                            class="font-weight-bold font-14px mb-1"
                          >
                            {{ $t("SignupStep3.extrabed") }}{{ $t("SignupStep3.child") }}
                          </label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text font-14px">
                                {{ $t("SigninCalendar.bulk_price_unit") }}
                              </div>
                            </div>
                            <input
                              type="number"
                              min="0"
                              @keypress="isNumber($event)"
                              class="form-control boder-prepend-left font-14px"
                              :id="`${item.activity}_extra_bed_child_rate`"
                              placeholder
                              v-model="item.extra_bed_child"
                              @focus="getfocus(`${item.activity}_extra_bed_child_rate`)"
                              @change="getfocus(`${item.activity}_extra_bed_child_rate`)"
                              @mouseleave="
                                mouseOver(`${item.activity}_extra_bed_child_rate`)
                              "
                            />
                          </div>
                        </v-col>

                        <v-col
                          v-if="bulk_package_items.length && item.unit == 'person'"
                          cols="12"
                          sm="12"
                          md="6"
                          lg="6"
                          class="py-0"
                        >
                          <label
                            for="issueinput3"
                            class="font-weight-bold font-14px mb-1"
                            >{{ $t("SignupStep3.adult") }}</label
                          >
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text font-14px">
                                {{ $t("SigninCalendar.bulk_price_unit") }}
                              </div>
                            </div>
                            <input
                              type="number"
                              min="0"
                              @keypress="isNumber($event)"
                              class="form-control boder-prepend-left font-14px"
                              :id="`${item.activity}_adult_rate`"
                              placeholder
                              v-model="item.adult_rate"
                              @focus="getfocus(`${item.activity}_adult_rate`)"
                              @change="getfocus(`${item.activity}_adult_rate`)"
                              @mouseleave="mouseOver(`${item.activity}_adult_rate`)"
                            />
                          </div>
                        </v-col>
                        <v-col
                          v-if="bulk_package_items.length && item.unit == 'person'"
                          cols="12"
                          sm="12"
                          md="6"
                          lg="6"
                          class="py-0"
                        >
                          <label
                            for="issueinput3"
                            class="font-weight-bold font-14px mb-1"
                            >{{ $t("SignupStep3.child") }}</label
                          >
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text font-14px">
                                {{ $t("SigninCalendar.bulk_price_unit") }}
                              </div>
                            </div>
                            <input
                              type="number"
                              min="0"
                              @keypress="isNumber($event)"
                              class="form-control boder-prepend-left font-14px"
                              :id="`${item.activity}_child_rate`"
                              placeholder
                              v-model="item.child_rate"
                              @focus="getfocus(`${item.activity}_child_rate`)"
                              @change="getfocus(`${item.activity}_child_rate`)"
                              @mouseleave="mouseOver(`${item.activity}_child_rate`)"
                            />
                          </div>
                        </v-col>
                        <!-- <v-col
                          v-if="bulk_package_items.length"
                          cols="12"
                          sm="12"
                          md="12"
                          lg="12"
                        >
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text font-14px">
                                {{ $t("SigninCalendar.bulk_price_unit") }}
                              </div>
                            </div>
                            <input
                              type="number"
                              min="0"
                              @keypress="isNumber($event)"
                              class="form-control boder-prepend-left font-14px"
                              :id="`${item.activity}_rate`"
                              placeholder=""
                              v-model="item.base_rate"
                              @focus="getfocus(`${item.activity}_rate`)"
                              @change="getfocus(`${item.activity}_rate`)"
                              @mouseleave="mouseOver(`${item.activity}_rate`)"
                            />
                          </div>
                        </v-col>-->
                      </v-row>
                      <v-col
                        v-if="bulk_package_items.length"
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                      >
                        <label
                          for="inputPassword"
                          class="col-form-label font-weight-bold font-14px"
                        >
                          {{ $t("SigninCalendar.bulk_package_price") }} :
                          <span class="font-weight-normal font-14px">
                            {{ $t("SigninCalendar.bulk_price_unit")
                            }}{{ totalPackagePriceText() }}
                          </span>
                        </label>
                      </v-col>

                      <v-col cols="12" sm="12" md="12" lg="12">
                        <label
                          for="inputPassword"
                          class="col-form-label font-weight-bold font-14px"
                        >
                          {{ $t("SigninCalendar.bulk_total_price") }} :
                          <span class="font-weight-normal font-14px">
                            {{ $t("SigninCalendar.bulk_price_unit")
                            }}{{ totalRatePlanPriceText() }}
                          </span>
                        </label>
                      </v-col>

                      <v-col cols="12" sm="12" md="12" lg="12">
                        <small>
                          {{ $t("SigninCalendar.bulk_apply_change_daterange") }}:
                          {{ bulk_date_start }}
                          {{ $t("SigninTeamPurchase.level_to") }}
                          {{ bulk_date_end }}
                        </small>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="12" class="d-flex flex-row">
                        <button
                          type="button"
                          class="btn btn-primary btn-min-width mb-1 mr-1 font-14px"
                          @click="saveChangeBulk"
                        >
                          {{ $t("SigninCalendar.btn_save_change") }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-light btn-min-width mb-1 font-14px"
                          @click="toggleBulkEditMenu"
                        >
                          {{ $t("SigninCalendar.btn_cancel") }}
                        </button>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                  <v-divider />
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="link font-weight-bold pa-0 pr-3">
                      {{ $t("SigninCalendar.bulk_room_status") }}
                      <br />
                      <small>{{ $t("SigninCalendar.bulk_openbook_room") }}</small>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-radio-group row v-model="bulk_active">
                      <v-radio
                        :label="`${$t('SigninCalendar.bulk_can_reserve_room')}`"
                        value="active"
                        color="#1e9ff2"
                        class="font-14px"
                      ></v-radio>
                      <v-radio
                        :label="`${$t('SigninCalendar.bulk_not_reserve_room')}`"
                        value="inactive"
                        color="#1e9ff2"
                        class="font-14px"
                      ></v-radio>
                    </v-radio-group>
                    <div>
                      <small>
                        {{ $t("SigninCalendar.bulk_apply_change_daterange") }}:
                        {{ bulk_date_start }}
                        {{ $t("SigninTeamPurchase.level_to") }}
                        {{ bulk_date_end }}
                      </small>
                    </div>
                    <div class="d-flex flex-row mt-5">
                      <button
                        type="button"
                        class="btn btn-primary btn-min-width mb-1 mr-1 font-14px"
                        @click="saveChangeBulk"
                      >
                        {{ $t("SigninCalendar.btn_save_change") }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-light btn-min-width mb-1 font-14px"
                        @click="toggleBulkEditMenu"
                      >
                        {{ $t("SigninCalendar.btn_cancel") }}
                      </button>
                    </div>
                  </v-expansion-panel-content>
                  <v-divider />
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="link font-weight-bold pa-0 pr-3">
                      {{ $t("SigninCalendar.bulk_requirement") }}
                      <br />
                      <small>{{ $t("SigninCalendar.bulk_modify_requirement") }}</small>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      class="dropdown select"
                      @click="getfocus('select_rateplan_req')"
                      @mouseleave="mouseOver('select_rateplan_req')"
                    >
                      <a id="select_rateplan_req" style="z-index: 99999">
                        <v-select
                          v-model="bulk_rateplan_minimum"
                          :items="bulk_rateplan_minimum_items"
                          item-text="name"
                          item-value="value"
                          label
                          :placeholder="$t('SigninCalendar.bulk_select_rateplan')"
                          :no-data-text="$t('SignupStep.nodata')"
                          color="#1e9ff2"
                          class="font-14px"
                          solo
                          flat
                          dense
                          single-line
                          hide-details
                          @change="selectBulkRateplanMinimumFunc"
                        >
                          <template v-slot:item="data">
                            <v-list-item-content
                              @mouseenter="getfocus('select_rateplan_req')"
                              @mouseleave="mouseOver('select_rateplan_req')"
                            >
                              <v-list-item-title
                                v-html="data.item.name"
                                class="title-signup-sj font-14px"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </a>
                    </div>
                    <label for="issueinput5" class="font-weight-bold font-14px my-3">{{
                      $t("SigninCalendar.bulk_min_stay")
                    }}</label>
                    <div
                      class="dropdown select"
                      @click="getfocus('select_min_stay')"
                      @mouseleave="mouseOver('select_min_stay')"
                    >
                      <a id="select_min_stay" style="z-index: 99999">
                        <v-select
                          v-model="bulk_minimum_stay"
                          :items="itemsBulkMinimumStay"
                          label
                          :placeholder="$t('SignupStep.select_one')"
                          :no-data-text="$t('SignupStep.nodata')"
                          color="#1e9ff2"
                          class="font-14px"
                          solo
                          flat
                          dense
                          single-line
                          hide-details
                        >
                          <template v-slot:item="data">
                            <v-list-item-content
                              @mouseenter="getfocus('select_min_stay')"
                              @mouseleave="mouseOver('select_min_stay')"
                            >
                              <v-list-item-title
                                v-html="data.item"
                                class="title-signup-sj font-14px"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </a>
                    </div>
                    <div class="d-flex flex-row mt-5">
                      <button
                        type="button"
                        class="btn btn-primary btn-min-width mb-1 mr-1 font-14px"
                        @click="saveChangeBulk"
                      >
                        {{ $t("SigninCalendar.btn_save_change") }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-light btn-min-width mb-1 font-14px"
                        @click="toggleBulkEditMenu"
                      >
                        {{ $t("SigninCalendar.btn_cancel") }}
                      </button>
                    </div>
                  </v-expansion-panel-content>
                  <v-divider />
                </v-expansion-panel>
              </v-expansion-panels>
            </ul>
          </div>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <v-overlay :value="loadingOverlay" color="white" :z-index="1200">
      <img :src="require('@/assets/img/thumb/loading.gif')" class="img-gif-loading" />
    </v-overlay>
  </v-app>
</template>

<script>
import i18n from "@/plugins/i18n";
import swal from "sweetalert";
import { EventBus } from "@/EventBus";
import moment from "moment";
import { Slide } from "vue-burger-menu";
import axios from "axios";

export default {
  name: "SigninCalendarScreen",
  metaInfo: {
    title: "Calendar",
    titleTemplate: "%s | Aigent",
  },
  components: {
    Slide,
  },
  data: () => ({
    host: location.host,
    drawer: true,
    mini: false,
    listGroup: false,
    overlay: false,
    loadingOverlay: false,
    group: null,
    itemsLanguage: [
      {
        title: "EN",
        name: "EN (UK)",
        value: "en",
        img: require("@/assets/thumb/flag-en.svg"),
      },
      {
        title: "ไทย",
        name: "TH",
        value: "th",
        img: require("@/assets/thumb/flag-th.svg"),
      },
      {
        title: "中国",
        name: "CN",
        value: "cn",
        img: require("@/assets/thumb/flag-cn.svg"),
      },
    ],
    selectedLanguage: {
      value: "en",
      img: require("@/assets/thumb/flag-en.svg"),
    },
    flagBtn: "",
    keepToken: "",
    userToken: "",
    userEmail: "",
    propertyID: "",
    dialogExit: false,
    canclicknoexit: true,
    exitEvent: true,
    initStartStep: 0,
    itemsNavList: [],
    menu: [],
    itemsPropertyOfUser: [],
    propertyName: "",
    coverImage: "",
    showUserMenu: false,
    itemsUser: [],
    userData: null,
    showPropertyMenu: false,

    moment: moment,
    select_room: null,
    itemsAllRooms: [],
    select_filter_room: null,
    itemsFilterRooms: [],
    cb_priceadjust: false,
    cb_reservereq: false,
    menu_daterange: false,
    select_daterange: [],
    filter_select_daterange: [],

    today: "2022-01-01",
    isSideBarOpen: null,
    menuOpen: true,
    menuStatus: false,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: ["#e8107f", "#1e9ff2"],
    groupHeaders: [],
    headers: [],
    desserts: [
      {
        name: "Test",
      },
    ],
    itemsRoomCalendar: [],
    itemsDays: [],

    propertyData: null,
    calendarData: null,
    itemsRatePlan: [],
    itemsDefaultRatePlan: [],
    package_total_price: 0,
    total_price: 0,
    itemsDefaultCalendar: [],
    itemsCalendar: [],
    itemsDefaultActivity: [],

    bulk_item: null,
    bulk_room_name: "",
    bulk_date_start: "",
    bulk_date_end: "",
    bulk_itemsDays: [],
    bulk_salenum_room: 0,
    bulk_rateplan: null,
    bulk_per_room: false,
    bulk_per_guest: false,
    bulk_base_rate: null,
    bulk_extra_bed_adult: null,
    bulk_extra_bed_child: null,
    bulk_adult_rate: null,
    bulk_child_rate: null,
    bulk_package_items: [],
    bulk_minimum_stay_room_rateplan: null,
    bulk_status_room_rateplan: null,
    bulk_rateplan_minimum: null,
    bulk_rateplan_minimum_items: [],
    bulk_rateplan_items: [],
    bulk_minimum_stay: null,
    itemsBulkMinimumStay: [2, 3, 4, 5],
    bulk_active: null,

    showEditPrice: false,
    value_element_editprice: "",
    closeOnEditPrice: false,
    editPriceItems: null,
    editPriceRatePlan: null,
    edit_price_rate_plan_id: null,
    edit_price_active: null,
    edit_price_base_rate: 0,
    edit_price_extra_bed_adult: 0,
    edit_price_extra_bed_child: 0,
    edit_price_adult_rate: 0,
    edit_price_child_rate: 0,
    edit_price_package_items: [],

    value_element_editroom: "",
    closeOnEditRoom: false,
    closeOnShowRoomSell: false,
    closeOnShowBookedRoom: false,
    editRoomDate: null,
    editRoomDates: null,
    editRoomItems: null,
    edit_room_number: 0,
    edit_room_number_remain: 0,
    edit_room_rateplan: [],
    edit_room_status: null,
    itemsDefaultRoomSell: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    itemsRoomSell: [],
    widthSideBar: 400,
    bulk_room_detail_property: null,
    bulk_items_package_total: 0,
    bulk_rateplan_total: 0,

    editOldPriceItems: {},
    componentEditRoom: 0,
    loadingEditRoomNumber: false,
    loadingShowRoomSellNumber: false,
    remainDayOut: false,
    loadingManualCheckin: false,
    manualCheckinValue: null,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    if (!self.$store.state.language && !localStorage.getItem("language"))
      self.getCountryIP();
    else self.initFlagsLanguage();
    self.initUserData();

    self.initAll();
  },
  watch: {
    group() {
      this.drawer = false;
    },
    // select_room() {
    //   const self = this;
    //   var tempRoomsCalendar = [];
    //   self.itemsDefaultCalendar?.map(el => {
    //     if (el?.default_room?._id) {
    //       if (self.select_room && self.select_room == el?.default_room?._id) {
    //         tempRoomsCalendar.push({
    //           ...el
    //         });
    //       } else if (!self.select_room) {
    //         tempRoomsCalendar.push({
    //           ...el
    //         });
    //       }
    //     }
    //   });
    //   self.itemsCalendar = tempRoomsCalendar;
    // },
    select_daterange() {
      const self = this;
      // console.log("select_daterange: ", self.select_daterange);
    },
    menuOpen() {
      const self = this;
      // console.log("menuOpen: ", self.menuOpen);
    },
    isSideBarOpen() {
      const self = this;
      // var body = document.body || document.getElementsByTagName("body")[0];
      // if (self.isSideBarOpen) {
      //   body.style["overflow-y"] = "hidden";
      // } else body.style["overflow-y"] = "auto";
    },
  },
  mounted() {
    EventBus.$on("changePathname", this.gotofillmoredata);
    EventBus.$on("goBackPage", this.goBack);
    EventBus.$on("loadingPage", this.loadingPage);
    EventBus.$on("loadingtillend", this.loadingtillend);
    EventBus.$on("endloading", this.endloading);
    EventBus.$on("goSignout", this.signoutFunc);
  },
  computed: {
    flag() {
      return (this.flagBtn = i18n.locale);
    },
    currentPage() {
      return this.$route.path;
    },
    dateRangeText() {
      const self = this;
      return self.filter_select_daterange.join(` ${self.$t("SigninPromotion.to")} `);
    },
    bulkMaxDate() {
      const self = this;
      return moment(self.filter_select_daterange[1])
        .add(18, "month")
        .format("YYYY-MM-DD");
    },
  },
  methods: {
    scrollToTop() {
      const self = this;
      var content = self.$refs.BulkEditMenuContainer;
      content.scrollTop = 0;
      window.scrollTo(0, 0);
    },
    stopmini() {
      this.mini = !this.mini;
      if (this.mini === true) this.listGroup = false;
    },
    confirmDialog() {
      // console.log("close");
    },
    gotopropmanage() {
      const self = this;
      self.$router
        .push({
          name: "SigninPropertyManagementScreen",
          params: {
            userToken: self?.userToken,
            propertyId: self?.propertyID,
          },
        })
        .catch(() => {});
    },
    gotouserFunc() {
      const self = this;
      self.$router
        .push({
          name: "SigninUserScreen",
          params: {
            userToken: self?.userToken,
            propertyId: self?.propertyID,
          },
        })
        .catch(() => {});
    },
    gotoinviteuserFunc() {
      const self = this;
      self.$router
        .push({
          name: "SigninInviteUserScreen",
          params: {
            userToken: self?.userToken,
            propertyId: self?.propertyID,
          },
        })
        .catch(() => {});
    },
    gotolink(item) {
      const self = this;
      self.$router
        .push({
          name: item.screen,
          params: {
            userToken: self?.userToken,
            propertyId: self?.propertyID,
          },
        })
        .catch(() => {});
    },
    async getCountryIP() {
      const self = this;
      var ipct = "en";
      // try {
      //   const res = await self.axios.get("http://ip-api.com/json");
      //   if (res.status == 200) {
      //     if (res.data.country == "Thailand") {
      //       ipct = "th";
      //     } else if (res.data.country == "China") {
      //       ipct = "cn";
      //     } else ipct = "en";
      //     localStorage.setItem("ipcountry", ipct);
      //   } else localStorage.setItem("ipcountry", ipct);
      // } catch (error) {
      //   console.log(error?.response?.data?.message || error);
      // } finally {
      i18n.locale = ipct;
      localStorage.setItem("language", ipct);
      const tempSelectLG = self.itemsLanguage.find((el) => el.value == ipct);
      self.changeLangFunc(tempSelectLG);
      // }
    },
    initFlagsLanguage() {
      const self = this;
      if (self.$store.state.language) {
        i18n.locale = self.$store.state.language;
        localStorage.setItem("language", self.$store.state.language);
        const tempSelectLG = self.itemsLanguage.find(
          (el) => el.value == self.$store.state.language
        );
        self.changeLangFunc(tempSelectLG);
      } else if (localStorage.getItem("language")) {
        i18n.locale = localStorage.getItem("language");
        const tempSelectLG = self.itemsLanguage.find(
          (el) => el.value == localStorage.getItem("language")
        );
        self.changeLangFunc(tempSelectLG);
      } else {
        self.getCountryIP();
      }
      const temp = self.itemsLanguage.find(
        (el) => el.value == localStorage.getItem("language")
      );
      self.selectedLanguage = temp;
    },
    changeLangFunc(item) {
      const self = this;
      self.$store.dispatch("changeLG", item.value);
      // self.$store.dispatch("language", item.value);
      i18n.locale = item.value;
      localStorage.setItem("language", item.value);
      self.selectedLanguage = {
        value: item.value,
        img: item.img,
      };
      self.initStartStep += 1;
      if (self?.propertyID) {
        self.itemsNavList = [];
        self.itemsUser = [];
        self.initUserData();
      }
      self.loadingtillend();
      self.initAll();
      // EventBus.$emit("refreshRightPhoto");
    },
    gotofillmoredata(item) {
      const self = this;
      var itemspathname;
      if (item?.params?.propertyId) {
        itemspathname = {
          ...item.params,
          userToken: self?.userToken,
        };
      } else if (self?.propertyID) {
        itemspathname = {
          ...item.params,
          propertyId: self?.propertyID,
          userToken: self?.userToken,
        };
      } else {
        itemspathname = {
          ...item.params,
          userToken: self?.userToken,
        };
      }
      self.$router.push({ name: item.name, params: itemspathname }).catch(() => {});
    },
    goexit() {
      const self = this;
      self.loadingOverlay = true;
      self.loadingtillend();
      const item = { link: "signin", name: "SigninScreen" };
      self.$store.dispatch("clearStoreData");
      axios.CancelToken.source();
      setTimeout(() => {
        self.$router.push({ name: item.name }).catch(() => {});
        self.loadingOverlay = false;
        self.endloading();
      }, 2000);
    },
    signoutFunc() {
      const self = this;
      self.keepToken =
        localStorage.getItem("Token") || self.$store.state.resSignUpData.access_token;
      if (self.keepToken) {
        self.showDialogExit();
      } else self.goexit();
    },
    confirmExit() {
      const self = this;
      self.loadingtillend();
      self.canclicknoexit = false;
      self.dialogExit = false;
      self.goexit();
    },
    goBack() {
      const self = this;
      self.overlay = true;
      setTimeout(() => {
        self.$router.go(-1);
        self.overlay = false;
      }, 200);
    },
    loadingPage() {
      // const self = this
      // self.overlay = true
      // setTimeout(() => {
      //   self.overlay = false
      // }, 800)
    },
    loadingtillend() {
      const self = this;
      self.overlay = true;
    },
    endloading() {
      const self = this;
      self.overlay = false;
    },
    openDrawerbar() {
      this.drawer = !this.drawer;
    },
    async initUserData() {
      const self = this;
      var temp = null;
      var tempEmail = "";
      var tempProperty = [];
      // self.loadingtillend();
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + "/my_user", {
          headers: {
            Authorization:
              self?.userToken ||
              localStorage?.getItem("Token") ||
              self?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") || self?.$store?.state?.language,
          },
        });
        if (res.status == 200) {
          temp = res?.data?.result || null;
          tempEmail = res?.data?.result?.email || "";
          if (temp?.property?.length) {
            temp.property?.map((ele) => {
              ele?.properties_detail?.map((el) => {
                if (el?.name)
                  tempProperty.push({
                    _id: el._id,
                    property_number: el?.property_number || el?._id,
                    name: el?.name || el?._id,
                  });
              });
            });
          }
        }
      } catch (error) {
        self.endloading();
        console.log(error?.response?.data?.message || error);
      } finally {
        self.endloading();
        self.userData = temp;
        // console.log("user: ", self.userData);
        self.userEmail = tempEmail;
        self.itemsPropertyOfUser = tempProperty || [];
        self.initPropertyData();

        if (
          (localStorage.getItem("Token") == "" || !localStorage.getItem("Token")) &&
          self.userToken
        ) {
          localStorage.setItem("Token", self.userToken);
        }
        if (self.propertyData?.voucher_suspend) {
          self.itemsNavList = [
            {
              _id: "11",
              icon: "mdi-cog-outline",
              name: self.$t("SigninSetting.setting"),
              link: "provider/setting",
              screen: null,
              submenu: [
                {
                  _id: "111",
                  name: self.$t("SigninSetting.general"),
                  link: "provider/setting/generalinfo",
                  screen: "SigninGeneralInfoScreen",
                },
              ],
            },
          ];
        } else {
          if (self.host == 'aigent-dev-hotel.gramick.dev' || self.host == 'localhost:3000') {
            self.itemsNavList = [
              {
                _id: "1",
                icon: "mdi-gift-outline",
                name: self.$t("SigninVoucherList.title"),
                link: "provider/voucher",
                screen: "SigninVoucherScreen",
                submenu: null,
              },
              {
                _id: "2",
                icon: "mdi-calendar-month-outline",
                name: self.$t("SigninCalendar.route_title"),
                link: "provider/calendar",
                screen: "SigninCalendarScreen",
                submenu: null,
              },
              {
                _id: "3",
                icon: "mdi-archive-outline",
                name: self.$t("SigninRateAndInventory.rateandinventory"),
                link: "provider/rateplanandinventory",
                screen: null,
                submenu: [
                  {
                    _id: "31",
                    name: self.$t("SigninRateAndInventory.rateplan"),
                    link: "provider/rateplanandinventory/rateplan",
                    screen: "SigninRatePlanScreen",
                  },
                  // {
                  //   _id: "32",
                  //   name: self.$t("SigninRateAndInventory.openclose"),
                  //   link: "provider/rateplanandinventory/openclose",
                  //   screen: "SigninOpenCloseScreen",
                  // },
                ],
              },
              {
                _id: "4",
                icon: "mdi-tag-multiple-outline",
                name: self.$t("SigninPromotion.route_title"),
                link: "provider/promotion",
                screen: "SigninPromotionScreen",
                submenu: null,
              },
              {
                _id: "6",
                icon: "mdi-diamond-stone",
                name: self.$t("SigninTeamPurchase.route_title"),
                link: "provider/team_purchase",
                screen: "SigninTeamPurchaseScreen",
                submenu: null,
              },
              {
                _id: "8",
                icon: "mdi-cash-multiple",
                name: self.$t("SigninFinance.title_finance"),
                link: "provider/finance",
                screen: null,
                submenu: [
                  {
                    _id: "81",
                    name: self.$t("SigninFinance.title_payout"),
                    link: "provider/finance/payout",
                    screen: "SigninPayoutScreen",
                  },
                  {
                    _id: "82",
                    name: self.$t("SigninFinance.title_invoiceandbank"),
                    link: "provider/finance/invoiceandbank",
                    screen: "SigninInvoiceAndBankScreen",
                  },
                ],
              },
              {
                _id: "11",
                icon: "mdi-cog-outline",
                name: self.$t("SigninSetting.setting"),
                link: "provider/setting",
                screen: null,
                submenu: [
                  {
                    _id: "111",
                    name: self.$t("SigninSetting.general"),
                    link: "provider/setting/generalinfo",
                    screen: "SigninGeneralInfoScreen",
                  },
                  {
                    _id: "112",
                    name: self.$t("SigninSetting.roomdetail"),
                    link: "provider/setting/room/details",
                    screen: "SigninRoomDetailsScreen",
                  },
                  {
                    _id: "113",
                    name: self.$t("SigninSetting.roomamenity"),
                    link: "provider/setting/room/amenity",
                    screen: "SigninRoomAmenityScreen",
                  },
                  {
                    _id: "114",
                    name: self.$t("SigninSetting.hotelfacility"),
                    link: "provider/setting/hotel/facilities",
                    screen: "SigninHotelFacilitiesScreen",
                  },
                  {
                    _id: "115",
                    name: self.$t("SigninSetting.activity"),
                    link: "provider/setting/activity",
                    screen: "SigninActivityScreen",
                  },
                  {
                    _id: "116",
                    name: self.$t("SigninSetting.photo"),
                    link: "provider/setting/photo",
                    screen: "SigninPhotoScreen",
                  },
                  {
                    _id: "118",
                    name: self.$t("SigninSetting.policy"),
                    link: "provider/setting/policy",
                    screen: "SigninPolicyScreen",
                  },
                  {
                    _id: "120",
                    name: self.$t("SigninSetting.nearby"),
                    link: "provider/setting/nearby",
                    screen: "SigninWhatNearByScreen",
                  },
                  {
                    _id: "121",
                    name: self.$t("SigninSetting.healthandsafety"),
                    link: "provider/setting/healthandsafety",
                    screen: "SigninHealthAndSafetyScreen",
                  },
                  {
                    _id: "123",
                    name: self.$t("SigninSetting.weekendandholiday"),
                    link: "provider/setting/weekendandholiday",
                    screen: "SigninWeekendAndHolidayScreen",
                  },
                  {
                    _id: "124",
                    name: self.$t("SigninSetting.seasonal"),
                    link: "provider/setting/seasonal",
                    screen: "SigninSeasonalScreen",
                  },
                ],
              },
              {
                _id: "12",
                icon: "mdi-account-outline",
                name: self.$t("SigninUserAndRole.title"),
                link: "provider/userandrole",
                screen: null,
                submenu: [
                  // {
                  //   _id: "12_1",
                  //   name: self.$t("SigninUserAndRole.title_create_group"),
                  //   link: "provider/userandrole/create_group",
                  //   screen: "SigninCreateGroupScreen",
                  // },
                  {
                    _id: "12_2",
                    name: self.$t("SigninUserAndRole.title_user"),
                    link: "provider/userandrole/user",
                    screen: "SigninUserScreen",
                  },
                  // {
                  //   _id: "12_3",
                  //   name: self.$t("SigninUserAndRole.title_role"),
                  //   link: "provider/userandrole/role",
                  //   screen: "SigninRoleScreen",
                  // },
                  {
                    _id: "12_4",
                    name: self.$t("SigninUserAndRole.nav_invite_user"),
                    link: "provider/userandrole/invite_user",
                    screen: "SigninInviteUserScreen",
                  },
                ],
              },
            ];
          } else {
            self.itemsNavList = [
              {
                _id: "1",
                icon: "mdi-gift-outline",
                name: self.$t("SigninVoucherList.title"),
                link: "provider/voucher",
                screen: "SigninVoucherScreen",
                submenu: null,
              },
              {
                _id: "2",
                icon: "mdi-calendar-month-outline",
                name: self.$t("SigninCalendar.route_title"),
                link: "provider/calendar",
                screen: "SigninCalendarScreen",
                submenu: null,
              },
              {
                _id: "3",
                icon: "mdi-archive-outline",
                name: self.$t("SigninRateAndInventory.rateandinventory"),
                link: "provider/rateplanandinventory",
                screen: null,
                submenu: [
                  {
                    _id: "31",
                    name: self.$t("SigninRateAndInventory.rateplan"),
                    link: "provider/rateplanandinventory/rateplan",
                    screen: "SigninRatePlanScreen",
                  },
                  // {
                  //   _id: "32",
                  //   name: self.$t("SigninRateAndInventory.openclose"),
                  //   link: "provider/rateplanandinventory/openclose",
                  //   screen: "SigninOpenCloseScreen",
                  // },
                ],
              },
              {
                _id: "4",
                icon: "mdi-tag-multiple-outline",
                name: self.$t("SigninPromotion.route_title"),
                link: "provider/promotion",
                screen: "SigninPromotionScreen",
                submenu: null,
              },
              {
                _id: "6",
                icon: "mdi-diamond-stone",
                name: self.$t("SigninTeamPurchase.route_title"),
                link: "provider/team_purchase",
                screen: "SigninTeamPurchaseScreen",
                submenu: null,
              },
              {
                _id: "8",
                icon: "mdi-cash-multiple",
                name: self.$t("SigninFinance.title_finance"),
                link: "provider/finance",
                screen: null,
                submenu: [
                  {
                    _id: "81",
                    name: self.$t("SigninFinance.title_payout"),
                    link: "provider/finance/payout",
                    screen: "SigninPayoutScreen",
                  },
                  {
                    _id: "82",
                    name: self.$t("SigninFinance.title_invoiceandbank"),
                    link: "provider/finance/invoiceandbank",
                    screen: "SigninInvoiceAndBankScreen",
                  },
                ],
              },
              {
                _id: "11",
                icon: "mdi-cog-outline",
                name: self.$t("SigninSetting.setting"),
                link: "provider/setting",
                screen: null,
                submenu: [
                  {
                    _id: "111",
                    name: self.$t("SigninSetting.general"),
                    link: "provider/setting/generalinfo",
                    screen: "SigninGeneralInfoScreen",
                  },
                  {
                    _id: "112",
                    name: self.$t("SigninSetting.roomdetail"),
                    link: "provider/setting/room/details",
                    screen: "SigninRoomDetailsScreen",
                  },
                  {
                    _id: "113",
                    name: self.$t("SigninSetting.roomamenity"),
                    link: "provider/setting/room/amenity",
                    screen: "SigninRoomAmenityScreen",
                  },
                  {
                    _id: "114",
                    name: self.$t("SigninSetting.hotelfacility"),
                    link: "provider/setting/hotel/facilities",
                    screen: "SigninHotelFacilitiesScreen",
                  },
                  {
                    _id: "115",
                    name: self.$t("SigninSetting.activity"),
                    link: "provider/setting/activity",
                    screen: "SigninActivityScreen",
                  },
                  {
                    _id: "116",
                    name: self.$t("SigninSetting.photo"),
                    link: "provider/setting/photo",
                    screen: "SigninPhotoScreen",
                  },
                  {
                    _id: "118",
                    name: self.$t("SigninSetting.policy"),
                    link: "provider/setting/policy",
                    screen: "SigninPolicyScreen",
                  },
                  {
                    _id: "120",
                    name: self.$t("SigninSetting.nearby"),
                    link: "provider/setting/nearby",
                    screen: "SigninWhatNearByScreen",
                  },
                  {
                    _id: "121",
                    name: self.$t("SigninSetting.healthandsafety"),
                    link: "provider/setting/healthandsafety",
                    screen: "SigninHealthAndSafetyScreen",
                  },
                  {
                    _id: "123",
                    name: self.$t("SigninSetting.weekendandholiday"),
                    link: "provider/setting/weekendandholiday",
                    screen: "SigninWeekendAndHolidayScreen",
                  },
                  {
                    _id: "124",
                    name: self.$t("SigninSetting.seasonal"),
                    link: "provider/setting/seasonal",
                    screen: "SigninSeasonalScreen",
                  },
                ],
              },
              {
                _id: "12",
                icon: "mdi-account-outline",
                name: self.$t("SigninUserAndRole.title"),
                link: "provider/userandrole",
                screen: null,
                submenu: [
                  // {
                  //   _id: "12_1",
                  //   name: self.$t("SigninUserAndRole.title_create_group"),
                  //   link: "provider/userandrole/create_group",
                  //   screen: "SigninCreateGroupScreen",
                  // },
                  {
                    _id: "12_2",
                    name: self.$t("SigninUserAndRole.title_user"),
                    link: "provider/userandrole/user",
                    screen: "SigninUserScreen",
                  },
                  // {
                  //   _id: "12_3",
                  //   name: self.$t("SigninUserAndRole.title_role"),
                  //   link: "provider/userandrole/role",
                  //   screen: "SigninRoleScreen",
                  // },
                  {
                    _id: "12_4",
                    name: self.$t("SigninUserAndRole.nav_invite_user"),
                    link: "provider/userandrole/invite_user",
                    screen: "SigninInviteUserScreen",
                  },
                ],
              },
            ];
          }
            self.itemsUser = [
              {
                _id: 99999,
                icon: "mdi-account-outline",
                name: self.$t("SigninToolbar.editprofile"),
              },
              {
                _id: 100000,
                icon: "mdi-clipboard-outline",
                name: self.$t("SigninToolbar.todo"),
              },
              {
                _id: 100001,
                icon: "mdi-checkbox-marked-outline",
                name: self.$t("SigninToolbar.task"),
              },
            ];
        }

        // if (self?.userData?.role == "provider") {
        //   self.itemsNavList.push({
        //     _id: "12",
        //     icon: "mdi-account-outline",
        //     name: self.$t("SigninUserAndRole.title"),
        //     link: "provider/userandrole",
        //     screen: null,
        //     submenu: [
        //       {
        //         _id: "12_4",
        //         name: self.$t("SigninUserAndRole.nav_invite_user"),
        //         link: "provider/userandrole/invite_user",
        //         screen: "SigninInviteUserScreen"
        //       }
        //     ]
        //   });
        // }
      }
    },
    async initPropertyData() {
      const self = this;
      var temp = null;
      self.manualCheckinValue = null;
      // self.loadingtillend();
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${self?.propertyID || self?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          if (res?.data?.result?.length) temp = res?.data?.result[0] || null;
          else self.goexit();
        }
      } catch (error) {
        self.endloading();
        console.log(error?.response?.data?.message || error);
        self.goexit();
      } finally {
        self.endloading();
        self.propertyData = temp;
        self.manualCheckinValue = temp?.manual_status || false;
        // console.log("propertyData: ", temp, temp?.manual_status, self.manualCheckinValue);
        const tempPropertyNumber = self?.itemsPropertyOfUser?.find(
          (p) => p._id == self.propertyID
        );
        if (temp?.name && temp?.property_type)
          self.propertyName = `${temp?.name || ""} (ID: ${
            tempPropertyNumber?.property_number
          })`;
        self.coverImage = temp?.cover_picture
          ? `${process.env.VUE_APP_PIC_API}/${temp?.cover_picture}`
          : null;
        // team purchase
        if (temp?.team_purchase?.date_of_countdown_app) {
          var expireDate = moment(temp?.team_purchase?.date_of_countdown_app)
            .add(temp?.team_purchase?.date_for_participation || 0, "day")
            .format("YYYY-MM-DD");
          var currentDate = new Date(new Date().setUTCHours(0, 0, 0, 0));
          var countDate = new Date(
            new Date(
              moment(temp?.team_purchase?.date_of_countdown_app).add(
                temp?.team_purchase?.date_for_participation || 0,
                "day"
              )
            ).setUTCHours(0, 0, 0, 0)
          );
          if (countDate < currentDate) {
            self.remainDayOut = true;
            self.updateTimeoutAppFunc();
          } else {
            self.remainDayOut = false;
          }
        } else self.remainDayOut = false;

        if (this.propertyData.voucher_suspend) {
          this.$router
            .push({
              name: "SigninGeneralInfoScreen",
              params: {
                userToken: this?.userToken,
                propertyId: this?.propertyID,
              },
            })
            .catch(() => {});
        }
      }
    },
    checkPropertyNameFunc() {
      const self = this;
      var tmpName = "";
      const tempPropertyNumber = self?.itemsPropertyOfUser?.find(
        (p) => p._id == self.propertyID
      );
      if (self.propertyData?.name && self.propertyData?.property_type)
        self.propertyName = `${self.propertyData?.name || ""} (ID: ${
          tempPropertyNumber?.property_number
        })`;
      tmpName = `${self.propertyData?.name || ""} (ID: ${
        tempPropertyNumber?.property_number
      })`;
      self.coverImage = self.propertyData?.cover_picture
        ? `${process.env.VUE_APP_PIC_API}/${self.propertyData?.cover_picture}`
        : null;
      return tmpName;
    },
    checkRoleUserFunc() {
      const self = this;
      return self.userData?.role || "provider";
    },
    changePropertyData(data) {
      const self = this;
      self.propertyID = data;
      const linkName = self.$router.history.current.name;
      self.$router
        .push({
          name: linkName,
          params: {
            userToken: self?.userToken,
            propertyId: data,
          },
        })
        .catch(() => {});
      window.location.reload();
    },
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    priceFormatter(value) {
      return Number(value || 0)
        ?.toFixed(2)
        ?.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    toggleBulkEditMenu(item) {
      const self = this;
      var tempDays = [];
      var tempRateplanMinimumStay = [];
      var tempRateplan = [];
      self.isSideBarOpen = !self.isSideBarOpen;
      // console.log("rate plan: ", self.itemsDefaultRatePlan);
      // self.itemsDefaultRatePlan?.map((ele) => {
      //   if (ele.per_guest) console.log("per guest: ", ele);
      // });
      // console.log("bulk edit: ", item);

      if (self.isSideBarOpen) {
        self.scrollToTop();
        self.bulk_item = item;
        self.bulk_room_name = item?.default_room?.room_name?.value || "";
        self.bulk_date_start = self.filter_select_daterange[0];
        self.bulk_date_end = self.filter_select_daterange[1];
        self.itemsDays.map((el) => {
          if (item.days) {
          } else tempDays.push({ ...el, check: true });
        });
        self.bulk_itemsDays = tempDays;
        self.bulk_salenum_room = item?.default_room?.room_number || 0;
        item?.rate_plans?.map((el) => {
          // console.log("el: ", el);
          if (!el?.default_rate_plan_price?.master_rate_plan_price)
            tempRateplan.push({
              ...el,
              name:
                el?.default_rate_plan_price?.name ||
                el?.default_rate_plan_price?.rate_plan?.value,
              value: el?._id,
            });
          if (el?.default_rate_plan_price?.minimum_stay > 1)
            tempRateplanMinimumStay.push({
              ...el,
              name:
                el?.default_rate_plan_price?.name ||
                el?.default_rate_plan_price?.rate_plan?.value,
              value: el?._id,
            });
        });
        self.bulk_active = "active";
        self.bulk_rateplan_minimum_items = tempRateplanMinimumStay;
        self.bulk_rateplan_items = tempRateplan;
      } else {
        self.bulk_item = null;
        self.bulk_room_name = "";
        self.bulk_salenum_room = 0;
        self.bulk_rateplan_minimum = null;
        self.bulk_minimum_stay = null;
        self.bulk_active = null;
        self.bulk_rateplan = null;
        self.bulk_base_rate = 0;
        self.bulk_extra_bed_adult = 0;
        self.bulk_extra_bed_child = 0;
        self.bulk_adult_rate = 0;
        self.bulk_child_rate = 0;
        self.bulk_minimum_stay_room_rateplan = null;
        self.bulk_status_room_rateplan = null;
        self.bulk_package_items = [];
        self.bulk_room_detail_property = null;
        self.bulk_items_package_total = 0;
        self.bulk_rateplan_total = 0;
      }
    },
    selectBulkRateplan() {
      const self = this;
      // console.log(self.itemsDefaultRatePlan, self.bulk_rateplan);
      const findRateplan = self.itemsDefaultRatePlan?.find(
        (p) => p._id == self.bulk_rateplan || p.rateplan_name == self.bulk_rateplan
      );
      var tempPackage = [];
      self.widthSideBar = 400;
      if (findRateplan && findRateplan?.rooms?.length) {
        const findRoomDetail = self?.propertyData?.rooms?.find(
          (p) => p?._id == findRateplan?.rooms[0]?.room?._id
        );
        self.bulk_room_detail_property = findRoomDetail;
        // console.log(
        //   "room: ",
        //   self.bulk_room_detail_property,
        //   self.bulk_room_detail_property.customer.normal >=
        //     self.bulk_room_detail_property.customer.max
        // );

        self.bulk_per_room = findRateplan?.per_room || false;
        self.bulk_per_guest = findRateplan?.per_guest || false;
        self.bulk_base_rate = findRateplan?.rooms[0]?.base_rate || 0;
        self.bulk_extra_bed_adult = findRateplan?.rooms[0]?.extra_bed_adult || 0;
        self.bulk_extra_bed_child = findRateplan?.rooms[0]?.extra_bed_child || 0;
        self.bulk_adult_rate = findRateplan?.rooms[0]?.adult_rate || 0;
        self.bulk_child_rate = findRateplan?.rooms[0]?.child_rate || 0;
        findRateplan?.package?.map((el) => {
          if (el?.unit == "voucher") self.widthSideBar = 600;
          tempPackage.push({
            activity: el?.activity?._id,
            unit: el?.unit,
            base_rate: el?.base_rate || 0,
            extra_bed_adult: el?.extra_bed_adult || 0,
            extra_bed_child: el?.extra_bed_child || 0,
            adult_rate: el?.adult_rate || 0,
            child_rate: el?.child_rate || 0,
          });
        });
        self.bulk_minimum_stay_room_rateplan = findRateplan?.minimum_stay;
        self.bulk_status_room_rateplan = findRateplan?.status;
      }
      self.bulk_package_items = tempPackage;
    },
    selectBulkRateplanMinimumFunc() {
      const self = this;
      // console.log(self.itemsDefaultRatePlan, self.bulk_rateplan_minimum);
      const findRateplan = self.itemsDefaultRatePlan?.find(
        (p) =>
          p._id == self.bulk_rateplan_minimum ||
          p.rateplan_name == self.bulk_rateplan_minimum
      );
      // console.log('rateplan: ', findRateplan)
      if (findRateplan) {
        self.bulk_minimum_stay =
          findRateplan?.minimum_stay > 1 ? findRateplan?.minimum_stay : null;
      }
    },
    checkNameActivity(id) {
      const self = this;
      const findName = self.itemsDefaultActivity?.find((p) => p._id == id);
      return findName?.name || self.$t("SigninActivity.name");
    },
    totalPackagePriceText() {
      const self = this;
      var sum = 0;

      // const sum = self?.bulk_package_items?.reduce(
      //   (partialSum, a) => partialSum + a?.base_rate,
      //   0
      // );
      self?.bulk_package_items?.map((item) => {
        if (item?.unit == "voucher") sum += item?.base_rate;
        else if (item?.unit == "person")
          sum += item?.adult_rate * self.bulk_room_detail_property?.customer?.normal;
      });
      self.bulk_items_package_total = sum;
      return self.priceFormatter(sum) || "0.00";
    },
    totalRatePlanPriceText() {
      const self = this;
      var sumPackage = 0;

      self?.bulk_package_items?.map((item) => {
        if (item?.unit == "voucher") sumPackage += item?.base_rate;
        else if (item?.unit == "person")
          sumPackage +=
            item?.adult_rate * self.bulk_room_detail_property?.customer?.normal;
      });
      const sum =
        Number(self?.bulk_base_rate) ||
        Number(
          self?.bulk_adult_rate *
            (self.bulk_per_guest ? self.bulk_room_detail_property?.customer?.normal : 1)
        ) + Number(sumPackage);
      return self.priceFormatter(sum) || "0.00";
    },
    async saveChangeBulk() {
      const self = this;
      var tempDays = "";
      var tempRateplan = [];
      var tempRateplanHavePrice = [];
      var tempRateplanPrice = [];
      var tempRateplanStatus = [];
      var tempRateplanMinStay = [];
      var tmpRoomUpdateRatePlan = [];
      var tmpRateplanPackage = [];
      var tmpMasterRateplanPackage = [];

      if (self.bulk_active) self.bulk_status_room_rateplan = self.bulk_active;

      self?.bulk_item?.rate_plans?.map((el, index) => {
        tmpRateplanPackage = [];
        tmpMasterRateplanPackage = [];
        if (self.bulk_rateplan_minimum) {
          // console.log(self.bulk_rateplan_minimum, el)
          if (el?.default_rate_plan_price?.package?.length) {
            el?.default_rate_plan_price?.package?.map((elem) => {
              tmpRateplanPackage.push({
                activity: elem?.activity?._id,
                unit: elem.unit,
                base_rate: elem?.base_rate || 0,
                extra_bed_adult: elem?.extra_bed_adult || 0,
                extra_bed_child: elem?.extra_bed_child || 0,
                adult_rate: elem?.adult_rate || 0,
                child_rate: elem?.child_rate || 0,
              });
            });
          }
          if (
            el?._id == self.bulk_rateplan_minimum ||
            el?.rateplan_name == self.bulk_rateplan_minimum
          ) {
            tempRateplanMinStay.push({
              // ...el,
              default_rate_plan_price: el?.default_rate_plan_price?._id,
              // base_rate: Number(el?.room_dates[0]?.rate_plan?.base_rate) || 0,
              // extra_bed_adult:
              //   Number(el?.room_dates[0]?.rate_plan?.extra_bed_adult) || 0,
              // extra_bed_child:
              //   Number(el?.room_dates[0]?.rate_plan?.extra_bed_child) || 0,
              // adult_rate: Number(el?.room_dates[0]?.rate_plan?.adult_rate) || 0,
              // child_rate: Number(el?.room_dates[0]?.rate_plan?.child_rate) || 0,
              // package: tmpRateplanPackage,
              minimum_stay: Number(self.bulk_minimum_stay),
              // status: el?.status,
            });
          } else {
            tempRateplanMinStay.push({
              default_rate_plan_price: el?.default_rate_plan_price?._id,
              // base_rate: Number(el?.room_dates[0]?.rate_plan?.base_rate) || 0,
              // extra_bed_adult:
              //   Number(el?.room_dates[0]?.rate_plan?.extra_bed_adult) || 0,
              // extra_bed_child:
              //   Number(el?.room_dates[0]?.rate_plan?.extra_bed_child) || 0,
              // adult_rate: Number(el?.room_dates[0]?.rate_plan?.adult_rate) || 0,
              // child_rate: Number(el?.room_dates[0]?.rate_plan?.child_rate) || 0,
              // package: tmpRateplanPackage,
              minimum_stay:
                el?.minimum_stay > 1
                  ? el?.minimum_stay
                  : el?.default_rate_plan_price?.minimum_stay > 1
                  ? el?.default_rate_plan_price?.minimum_stay
                  : null,
              // status: el?.status,
            });
          }
        } else if (self.bulk_rateplan) {
          if (el?._id == self.bulk_rateplan || el?.rateplan_name == self.bulk_rateplan) {
            tmpRoomUpdateRatePlan.push({
              room: el?.default_rate_plan_price?._id,
              base_rate: Number(self.bulk_base_rate) || 0,
              extra_bed_adult: Number(self.bulk_extra_bed_adult) || 0,
              extra_bed_child: Number(self.bulk_extra_bed_child) || 0,
              adult_rate: Number(self.bulk_adult_rate) || 0,
              child_rate: Number(self.bulk_child_rate) || 0,
            });
            tempRateplanPrice.push({
              // ...el,
              default_rate_plan_price: el?.default_rate_plan_price?._id,
              base_rate: Number(self.bulk_base_rate) || 0,
              extra_bed_adult: Number(self.bulk_extra_bed_adult) || 0,
              extra_bed_child: Number(self.bulk_extra_bed_child) || 0,
              adult_rate: Number(self.bulk_adult_rate) || 0,
              child_rate: Number(self.bulk_child_rate) || 0,
              package: self.bulk_package_items,
              minimum_stay: self.bulk_minimum_stay_room_rateplan,
              status: self.bulk_status_room_rateplan,
            });
          } else {
            // tmpRoomUpdateRatePlan.push(el);
            // tempRateplanPrice.push(el);
          }
          if (
            el?.default_rate_plan_price?.master_rate_plan_price?._id ==
              self.bulk_rateplan ||
            el?.default_rate_plan_price?.master_rate_plan_price == self.bulk_rateplan
          ) {
            // console.log(
            //   "find master_rate_plan_price: ",
            //   findRateplan,
            //   findRateplan?.rooms,
            //   el
            // );
            if (el?.default_rate_plan_price?.package?.length)
              el?.default_rate_plan_price?.package?.map((elem) => {
                tmpMasterRateplanPackage.push({
                  activity: elem?.activity?._id,
                  unit: elem.unit,
                  base_rate: elem?.base_rate || 0,
                  extra_bed_adult: elem?.extra_bed_adult || 0,
                  extra_bed_child: elem?.extra_bed_child || 0,
                  adult_rate: elem?.adult_rate || 0,
                  child_rate: elem?.child_rate || 0,
                });
              });

            tempRateplanPrice.push({
              default_rate_plan_price: el?.default_rate_plan_price?._id,
              base_rate:
                el?.default_rate_plan_price?.rate?.enum &&
                el?.default_rate_plan_price?.rate?.type == "increase"
                  ? (
                      Number(self.bulk_base_rate) *
                      ((100 + Number(el?.default_rate_plan_price?.rate?.enum)) / 100)
                    ).toFixed(2)
                  : el?.default_rate_plan_price?.rate?.enum &&
                    el?.default_rate_plan_price?.rate?.type == "decrease"
                  ? (
                      Number(self.bulk_base_rate) *
                      ((100 - Number(el?.default_rate_plan_price?.rate?.enum)) / 100)
                    ).toFixed(2)
                  : Number(self.bulk_base_rate) || 0,
              extra_bed_adult:
                el?.default_rate_plan_price?.rate?.enum &&
                el?.default_rate_plan_price?.rate?.type == "increase"
                  ? (
                      Number(self.bulk_extra_bed_adult) *
                      ((100 + Number(el?.default_rate_plan_price?.rate?.enum)) / 100)
                    ).toFixed(2)
                  : el?.default_rate_plan_price?.rate?.enum &&
                    el?.default_rate_plan_price?.rate?.type == "decrease"
                  ? (
                      Number(self.bulk_extra_bed_adult) *
                      ((100 - Number(el?.default_rate_plan_price?.rate?.enum)) / 100)
                    ).toFixed(2)
                  : Number(self.bulk_extra_bed_adult) || 0,
              extra_bed_child:
                el?.default_rate_plan_price?.rate?.enum &&
                el?.default_rate_plan_price?.rate?.type == "increase"
                  ? (
                      Number(self.bulk_extra_bed_child) *
                      ((100 + Number(el?.default_rate_plan_price?.rate?.enum)) / 100)
                    ).toFixed(2)
                  : el?.default_rate_plan_price?.rate?.enum &&
                    el?.default_rate_plan_price?.rate?.type == "decrease"
                  ? (
                      Number(self.bulk_extra_bed_child) *
                      ((100 - Number(el?.default_rate_plan_price?.rate?.enum)) / 100)
                    ).toFixed(2)
                  : Number(self.bulk_extra_bed_child) || 0,
              adult_rate:
                el?.default_rate_plan_price?.rate?.enum &&
                el?.default_rate_plan_price?.rate?.type == "increase"
                  ? (
                      Number(self.bulk_adult_rate) *
                      ((100 + Number(el?.default_rate_plan_price?.rate?.enum)) / 100)
                    ).toFixed(2)
                  : el?.default_rate_plan_price?.rate?.enum &&
                    el?.default_rate_plan_price?.rate?.type == "decrease"
                  ? (
                      Number(self.bulk_adult_rate) *
                      ((100 - Number(el?.default_rate_plan_price?.rate?.enum)) / 100)
                    ).toFixed(2)
                  : Number(self.bulk_adult_rate) || 0,
              child_rate:
                el?.default_rate_plan_price?.rate?.enum &&
                el?.default_rate_plan_price?.rate?.type == "increase"
                  ? (
                      Number(self.bulk_child_rate) *
                      ((100 + Number(el?.default_rate_plan_price?.rate?.enum)) / 100)
                    ).toFixed(2)
                  : el?.default_rate_plan_price?.rate?.enum &&
                    el?.default_rate_plan_price?.rate?.type == "decrease"
                  ? (
                      Number(self.bulk_child_rate) *
                      ((100 - Number(el?.default_rate_plan_price?.rate?.enum)) / 100)
                    ).toFixed(2)
                  : Number(self.bulk_child_rate) || 0,
              package: tmpMasterRateplanPackage,
              minimum_stay: el?.default_rate_plan_price?.minimum_stay,
              status: self.bulk_status_room_rateplan,
            });
          }
        } else {
          const findRoomsPrice = el?.default_rate_plan_price?.rooms?.find(
            (p) => p.room?._id == self.bulk_item?.default_room?._id
          );
          const findRateplan = self.itemsDefaultRatePlan?.find(
            (p) => p._id == el?.rateplan_id
          );
          if (el?.default_rate_plan_price?.package?.length)
            el?.default_rate_plan_price?.package?.map((elem) => {
              tmpRateplanPackage.push({
                activity: elem?.activity?._id,
                unit: elem.unit,
                base_rate: elem?.base_rate || 0,
                extra_bed_adult: elem?.extra_bed_adult || 0,
                extra_bed_child: elem?.extra_bed_child || 0,
                adult_rate: elem?.adult_rate || 0,
                child_rate: elem?.child_rate || 0,
              });
            });

          // console.log("findRoomsPrice: ", findRoomsPrice, el,findRateplan, el?.default_rate_plan_price?.rooms, 'rooms item: ', self.bulk_item);
          tempRateplan.push(el);
          tempRateplanStatus.push({
            default_rate_plan_price: el?.default_rate_plan_price?._id,
            // base_rate:
            //   Number(findRoomsPrice?.base_rate) ||
            //   Number(self.bulk_base_rate) ||
            //   0,
            // extra_bed_adult:
            //   Number(findRoomsPrice?.extra_bed_adult) ||
            //   Number(self.bulk_extra_bed_adult) ||
            //   0,
            // extra_bed_child:
            //   Number(findRoomsPrice?.extra_bed_child) ||
            //   Number(self.bulk_extra_bed_child) ||
            //   0,
            // adult_rate:
            //   Number(findRoomsPrice?.adult_rate) ||
            //   Number(self.bulk_adult_rate) ||
            //   0,
            // child_rate:
            //   Number(findRoomsPrice?.child_rate) ||
            //   Number(self.bulk_child_rate) ||
            //   0,
            // package: tmpRateplanPackage || self.bulk_package_items,
            // minimum_stay: self.bulk_minimum_stay_room_rateplan,
            status: self.bulk_status_room_rateplan,
          });
          // console.log("rate plan: ", findRateplan, self.bulk_item.default_room._id);
          const tmpRoomRate = findRateplan?.rooms?.find(
            (p) => p.room?._id == self.bulk_item.default_room._id
          );
          tempRateplanHavePrice.push({
            default_rate_plan_price: el?.default_rate_plan_price?._id,
            base_rate: Number(tmpRoomRate?.base_rate) || 0,
            extra_bed_adult: Number(tmpRoomRate?.extra_bed_adult) || 0,
            extra_bed_child: Number(tmpRoomRate?.extra_bed_child) || 0,
            adult_rate: Number(tmpRoomRate?.adult_rate) || 0,
            child_rate: Number(tmpRoomRate?.child_rate) || 0,
            package: tmpRateplanPackage || self.bulk_package_items,
            minimum_stay: self.bulk_minimum_stay_room_rateplan,
            status: self.bulk_status_room_rateplan,
          });
        }
      });

      const obj = {
        rooms: [
          {
            default_room: self.bulk_item.default_room._id,
            room_number: self.bulk_salenum_room ? Number(self.bulk_salenum_room) : null,
            room_plan:
              self?.bulk_rateplan_minimum && self.bulk_minimum_stay
                ? tempRateplanMinStay
                : self?.bulk_rateplan
                ? tempRateplanPrice
                : self.bulk_salenum_room
                ? tempRateplanHavePrice
                : tempRateplanStatus,
            status: self.bulk_active,
          },
        ],
      };

      self?.bulk_itemsDays?.map((el) => {
        if (el.check) {
          if (el._id == 0) {
            tempDays = "0";
          } else if (el._id == 1) {
            tempDays?.length ? (tempDays = `${tempDays},1`) : (tempDays = "1");
          } else if (el._id == 2) {
            tempDays?.length ? (tempDays = `${tempDays},2`) : (tempDays = "2");
          } else if (el._id == 3) {
            tempDays?.length ? (tempDays = `${tempDays},3`) : (tempDays = "3");
          } else if (el._id == 4) {
            tempDays?.length ? (tempDays = `${tempDays},4`) : (tempDays = "4");
          } else if (el._id == 5) {
            tempDays?.length ? (tempDays = `${tempDays},5`) : (tempDays = "5");
          } else if (el._id == 6) {
            tempDays?.length ? (tempDays = `${tempDays},6`) : (tempDays = "6");
          }
        }
      });

      // console.log(
      //   "toggle obj: ",
      //   obj,
      //   tempRateplanMinStay,
      //   tempRateplanPrice,
      //   tempRateplanStatus
      // );

      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/calendar/bulk?property=${self.propertyID}&start_date=${self.bulk_date_start}&end_date=${self.bulk_date_end}&day=${tempDays}`,
          obj,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          swal(
            self.$t("Alert.success_title"),
            self.$t("Alert.save_success"),
            self.$t("Alert.success_label"),
            {
              button: false,
              timer: 2000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.error_title"),
          error?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.error_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        EventBus.$emit("endloading");
        self.toggleBulkEditMenu();
        self.initAll();
      }
    },
    getfocusEditPrice(val, attrs, on) {
      var element = document.getElementById(val);
      if (this.value_element_editprice === "" && attrs && on) {
        element.classList.add("bg-yellow-focus");
        this.value_element_editprice = val;
      } else if (this.value_element_editprice && attrs && on) {
        document
          .getElementById(this.value_element_editprice)
          .classList.remove("bg-yellow-focus");
        element.classList.add("bg-yellow-focus");
        this.value_element_editprice = val;
      } else {
        document
          .getElementById(this.value_element_editprice)
          .classList.remove("bg-yellow-focus");
        this.value_element_editprice = "";
      }
    },
    getfocusShowPriceDetail(val, attrs, on) {
      var element = document.getElementById(val);
      if (this.value_element_editprice === "" && attrs && on) {
        element.classList.add("bg-gray-focus");
        this.value_element_editprice = val;
      } else if (this.value_element_editprice && attrs && on) {
        document
          .getElementById(this.value_element_editprice)
          .classList.remove("bg-gray-focus");
        element.classList.add("bg-gray-focus");
        this.value_element_editprice = val;
      } else {
        document
          .getElementById(this.value_element_editprice)
          .classList.remove("bg-gray-focus");
        this.value_element_editprice = "";
      }
    },
    editPriceFunc(ele, i, item, index, element, refresh) {
      const self = this;
      var needtofetchData = refresh || false;
      var tempPackage = [];
      const findRoomPrice = item?.default_rate_plan_price?.rooms?.find(
        (p) => p.room?._id == ele.rooms?.default_room?._id
      );
      self.edit_price_base_rate = 0;
      self.edit_price_extra_bed_adult = 0;
      self.edit_price_extra_bed_child = 0;
      self.edit_price_adult_rate = 0;
      self.edit_price_child_rate = 0;

      if (ele) {
        item.showEditPrice = true;
        self.showEditPrice = true;
        self.closeOnEditPrice = false;
        self.editPriceItems = ele;
        self.editPriceRatePlan = item;
        self.edit_price_rate_plan_id = item.default_rate_plan_price._id;
        self.edit_price_active =
          ele?.room_status ||
          item.default_rate_plan_price.status ||
          element?.room_dates[i]?.room_rateplans[index].status;
        // console.log("editPrice: ", ele, i, item, index, element);
        // console.log(
        //   self.editPriceItems,
        //   self.editPriceRatePlan,
        //   self.edit_price_active
        // );
        if (element?.room_dates[i]?.room_rateplans[index]?.package?.length) {
          element?.room_dates[i]?.room_rateplans[index]?.package?.map((elem) => {
            tempPackage.push({
              activity: elem?.activity?._id,
              unit: elem.unit,
              base_rate: elem?.base_rate || 0,
              extra_bed_adult: elem?.extra_bed_adult || 0,
              extra_bed_child: elem?.extra_bed_child || 0,
              adult_rate: elem?.adult_rate || 0,
              child_rate: elem?.child_rate || 0,
            });
          });
        } else if (item?.default_rate_plan_price?.package?.length) {
          item?.default_rate_plan_price?.package?.map((elem) => {
            tempPackage.push({
              activity: elem?.activity?._id,
              unit: elem.unit,
              base_rate: elem?.base_rate || 0,
              extra_bed_adult: elem?.extra_bed_adult || 0,
              extra_bed_child: elem?.extra_bed_child || 0,
              adult_rate: elem?.adult_rate || 0,
              child_rate: elem?.child_rate || 0,
            });
          });
        }
        self.edit_price_base_rate = ele?.room_rateplans[index]?.default_rate_plan_price
          ?.per_room
          ? element?.room_dates[i]?.room_rateplans[index]?.base_rate ||
            item?.default_rate_plan_price?.rooms[index]?.base_rate ||
            findRoomPrice?.base_rate ||
            0
          : null;
        self.edit_price_extra_bed_adult =
          ele?.room_customer_max > ele?.room_customer_normal
            ? ele?.room_rateplans[index]?.default_rate_plan_price?.per_room
              ? element?.room_dates[i]?.room_rateplans[index]?.extra_bed_adult ||
                item?.default_rate_plan_price?.rooms[index]?.extra_bed_adult ||
                findRoomPrice?.extra_bed_adult ||
                0
              : null
            : null;
        self.edit_price_extra_bed_child =
          ele?.room_customer_max > ele?.room_customer_normal
            ? ele?.room_rateplans[index]?.default_rate_plan_price?.per_room
              ? element?.room_dates[i]?.room_rateplans[index]?.extra_bed_child ||
                item?.default_rate_plan_price?.rooms[index]?.extra_bed_child ||
                findRoomPrice?.extra_bed_child ||
                0
              : null
            : null;
        self.edit_price_adult_rate = ele?.room_rateplans[index]?.default_rate_plan_price
          ?.per_guest
          ? element?.room_dates[i]?.room_rateplans[index]?.adult_rate ||
            item?.default_rate_plan_price?.rooms[index]?.adult_rate ||
            findRoomPrice?.adult_rate ||
            0
          : null;
        self.edit_price_child_rate = ele?.room_rateplans[index]?.default_rate_plan_price
          ?.per_guest
          ? element?.room_dates[i]?.room_rateplans[index]?.child_rate ||
            item?.default_rate_plan_price?.rooms[index]?.child_rate ||
            findRoomPrice?.child_rate ||
            0
          : null;

        self.edit_price_package_items = tempPackage;

        self.editOldPriceItems = {
          edit_price_active:
            ele?.room_status ||
            item.default_rate_plan_price.status ||
            element?.room_dates[i]?.room_rateplans[index].status,
          edit_price_base_rate: ele?.room_rateplans[index]?.default_rate_plan_price
            ?.per_room
            ? element?.room_dates[i]?.room_rateplans[index]?.base_rate ||
              item?.default_rate_plan_price?.rooms[index]?.base_rate ||
              findRoomPrice?.base_rate ||
              0
            : null,
          edit_price_extra_bed_adult: ele?.room_rateplans[index]?.default_rate_plan_price
            ?.per_room
            ? element?.room_dates[i]?.room_rateplans[index]?.extra_bed_adult ||
              item?.default_rate_plan_price?.rooms[index]?.extra_bed_adult ||
              findRoomPrice?.extra_bed_adult ||
              0
            : null,
          edit_price_extra_bed_child: ele?.room_rateplans[index]?.default_rate_plan_price
            ?.per_room
            ? element?.room_dates[i]?.room_rateplans[index]?.extra_bed_child ||
              item?.default_rate_plan_price?.rooms[index]?.extra_bed_child ||
              findRoomPrice?.extra_bed_child ||
              0
            : null,
          edit_price_adult_rate: ele?.room_rateplans[index]?.default_rate_plan_price
            ?.per_guest
            ? element?.room_dates[i]?.room_rateplans[index]?.adult_rate ||
              item?.default_rate_plan_price?.rooms[index]?.adult_rate ||
              findRoomPrice?.adult_rate ||
              0
            : null,
          edit_price_child_rate: ele?.room_rateplans[index]?.default_rate_plan_price
            ?.per_guest
            ? element?.room_dates[i]?.room_rateplans[index]?.child_rate ||
              item?.default_rate_plan_price?.rooms[index]?.child_rate ||
              findRoomPrice?.child_rate ||
              0
            : null,
          edit_price_package_items: tempPackage,
        };
        // console.log('old: ', self.editOldPriceItems)
      } else {
        // if (self.editOldPriceItems?.edit_price_active !== self.edit_price_active ||
        // self.editOldPriceItems?.edit_price_base_rate !== self.edit_price_base_rate ||
        // self.editOldPriceItems?.edit_price_extra_bed_adult !== self.edit_price_extra_bed_adult ||
        // self.editOldPriceItems?.edit_price_extra_bed_child !== self.edit_price_extra_bed_child ||
        // self.editOldPriceItems?.edit_price_adult_rate !== self.edit_price_adult_rate ||
        // self.editOldPriceItems?.edit_price_child_rate !== self.edit_price_child_rate) needtofetchData = true
        // else needtofetchData = false
        // if (needtofetchData) self.initAll();

        self.showEditPrice = false;
        self.closeOnEditPrice = true;
        setTimeout(() => {
          self.editPriceItems = null;
          self.editPriceRatePlan = null;
          self.edit_price_rate_plan_id = null;
          self.edit_price_active = null;
          self.edit_price_base_rate = 0;
          self.edit_price_extra_bed_adult = 0;
          self.edit_price_extra_bed_child = 0;
          self.edit_price_adult_rate = 0;
          self.edit_price_child_rate = 0;
          self.edit_price_package_items = [];
        }, 1000);
        if (needtofetchData) self.initAll();
      }
    },
    showPriceDetailFunc(ele, i, item, index, element) {
      const self = this;
      var tempPackage = [];
      const findRoomPrice = item?.default_rate_plan_price?.rooms?.find(
        (p) => p.room?._id == ele.rooms?.default_room?._id
      );

      if (ele) {
        item.showEditPrice = true;
        self.showEditPrice = true;
        self.closeOnEditPrice = false;
        self.editPriceItems = ele;
        self.editPriceRatePlan = item;
        self.edit_price_rate_plan_id = item.default_rate_plan_price._id;
        self.edit_price_active =
          ele?.room_status ||
          item.default_rate_plan_price.status ||
          element?.room_dates[i]?.room_rateplans[index].status;
        // console.log("editPrice: ", ele, item, element);
        // console.log("show: ", self.editPriceItems, self.editPriceRatePlan);
        if (element?.room_dates[i]?.room_rateplans[index]?.package?.length) {
          element?.room_dates[i]?.room_rateplans[index]?.package?.map((elem) => {
            tempPackage.push({
              activity: elem?.activity?._id,
              unit: elem.unit,
              base_rate: elem?.base_rate || 0,
              extra_bed_adult: elem?.extra_bed_adult || 0,
              extra_bed_child: elem?.extra_bed_child || 0,
              adult_rate: elem?.adult_rate || 0,
              child_rate: elem?.child_rate || 0,
            });
          });
        } else if (item?.default_rate_plan_price?.package?.length) {
          item?.default_rate_plan_price?.package?.map((elem) => {
            tempPackage.push({
              activity: elem?.activity?._id,
              unit: elem.unit,
              base_rate: elem?.base_rate || 0,
              extra_bed_adult: elem?.extra_bed_adult || 0,
              extra_bed_child: elem?.extra_bed_child || 0,
              adult_rate: elem?.adult_rate || 0,
              child_rate: elem?.child_rate || 0,
            });
          });
        }
        self.edit_price_base_rate = ele?.room_rateplans[index]?.default_rate_plan_price
          ?.per_room
          ? element?.room_dates[i]?.room_rateplans[index]?.base_rate ||
            item?.default_rate_plan_price?.rooms[index]?.base_rate ||
            findRoomPrice?.base_rate ||
            0
          : null;
        self.edit_price_extra_bed_adult = ele?.room_rateplans[index]
          ?.default_rate_plan_price?.per_room
          ? element?.room_dates[i]?.room_rateplans[index]?.extra_bed_adult ||
            item?.default_rate_plan_price?.rooms[index]?.extra_bed_adult ||
            findRoomPrice?.extra_bed_adult ||
            0
          : null;
        self.edit_price_extra_bed_child = ele?.room_rateplans[index]
          ?.default_rate_plan_price?.per_room
          ? element?.room_dates[i]?.room_rateplans[index]?.extra_bed_child ||
            item?.default_rate_plan_price?.rooms[index]?.extra_bed_child ||
            findRoomPrice?.extra_bed_child ||
            0
          : null;
        self.edit_price_adult_rate = ele?.room_rateplans[index]?.default_rate_plan_price
          ?.per_guest
          ? element?.room_dates[i]?.room_rateplans[index]?.adult_rate ||
            item?.default_rate_plan_price?.rooms[index]?.adult_rate ||
            findRoomPrice?.adult_rate ||
            0
          : null;
        self.edit_price_child_rate = ele?.room_rateplans[index]?.default_rate_plan_price
          ?.per_guest
          ? element?.room_dates[i]?.room_rateplans[index]?.child_rate ||
            item?.default_rate_plan_price?.rooms[index]?.child_rate ||
            findRoomPrice?.child_rate ||
            0
          : null;

        self.edit_price_package_items = tempPackage;
      } else {
        self.showEditPrice = false;
        self.closeOnEditPrice = true;
        self.editPriceItems = null;
        self.editPriceRatePlan = null;
        self.edit_price_rate_plan_id = null;
        self.edit_price_active = null;
        self.edit_price_base_rate = 0;
        self.edit_price_extra_bed_adult = 0;
        self.edit_price_extra_bed_child = 0;
        self.edit_price_adult_rate = 0;
        self.edit_price_child_rate = 0;
        self.edit_price_package_items = [];
      }
    },
    dateEditPriceText(item) {
      if (this.editPriceItems?.date) {
        return moment(this.editPriceItems.date).format("DD MMM YYYY");
      } else return "";
    },
    async saveEditPriceFunc() {
      const self = this;
      var tempRatePlan = [];
      var tmpRoomUpdateRatePlan = [];
      var tempPackage = [];
      self.editPriceItems?.room_rateplans?.map((el) => {
        tempPackage = [];
        if (el.default_rate_plan_price._id == self.edit_price_rate_plan_id) {
          tmpRoomUpdateRatePlan.push({
            room: el?.default_rate_plan_price?._id,
            base_rate: Number(self.edit_price_base_rate) || 0,
            extra_bed_adult: Number(self.edit_price_extra_bed_adult) || 0,
            extra_bed_child: Number(self.edit_price_extra_bed_child) || 0,
            adult_rate: Number(self.edit_price_adult_rate) || 0,
            child_rate: Number(self.edit_price_child_rate) || 0,
          });
          tempRatePlan.push(
            !self.manualCheckinValue
              ? {
                  default_rate_plan_price: self.edit_price_rate_plan_id,
                  base_rate: Number(self.edit_price_base_rate) || 0,
                  extra_bed_adult: Number(self.edit_price_extra_bed_adult) || 0,
                  extra_bed_child: Number(self.edit_price_extra_bed_child) || 0,
                  adult_rate: Number(self.edit_price_adult_rate) || 0,
                  child_rate: Number(self.edit_price_child_rate) || 0,
                  package: self.edit_price_package_items,
                  minimum_stay:
                    self.editPriceRatePlan?.default_rate_plan_price?.minimum_stay || null,
                  status: self.edit_price_active,
                }
              : {
                  default_rate_plan_price: self.edit_price_rate_plan_id,
                  base_rate: Number(self.edit_price_base_rate) || 0,
                  extra_bed_adult: Number(self.edit_price_extra_bed_adult) || 0,
                  extra_bed_child: Number(self.edit_price_extra_bed_child) || 0,
                  adult_rate: Number(self.edit_price_adult_rate) || 0,
                  child_rate: Number(self.edit_price_child_rate) || 0,
                  package: self.edit_price_package_items,
                  minimum_stay:
                    self.editPriceRatePlan?.default_rate_plan_price?.minimum_stay || null,
                }
          );
        } else {
          const checkMasterRatePlan =
            el?.default_rate_plan_price?.master_rate_plan_price?._id ==
              self.edit_price_rate_plan_id ||
            (el?.default_rate_plan_price?.master_rate_plan_price ==
              self.edit_price_rate_plan_id &&
              el);
          var new_base_rate,
            new_extra_adult,
            new_extra_child,
            new_adult_rate,
            new_child_rate = 0;

          if (checkMasterRatePlan) {
            new_base_rate =
              checkMasterRatePlan?.default_rate_plan_price?.rate?.type == "decrease"
                ? Number(self.edit_price_base_rate) *
                  ((100 - Number(checkMasterRatePlan.default_rate_plan_price.rate.enum)) /
                    100)
                : checkMasterRatePlan?.default_rate_plan_price?.rate?.type == "increase"
                ? Number(self.edit_price_base_rate) *
                  ((100 + Number(checkMasterRatePlan.default_rate_plan_price.rate.enum)) /
                    100)
                : Number(self.edit_price_base_rate);
            new_extra_adult =
              checkMasterRatePlan?.default_rate_plan_price?.rate?.type == "decrease"
                ? Number(self.edit_price_extra_bed_adult) *
                  ((100 - Number(checkMasterRatePlan.default_rate_plan_price.rate.enum)) /
                    100)
                : checkMasterRatePlan?.default_rate_plan_price?.rate?.type == "increase"
                ? Number(self.edit_price_extra_bed_adult) *
                  ((100 + Number(checkMasterRatePlan.default_rate_plan_price.rate.enum)) /
                    100)
                : Number(self.edit_price_extra_bed_adult);
            new_extra_child =
              checkMasterRatePlan?.default_rate_plan_price?.rate?.type == "decrease"
                ? Number(self.edit_price_extra_bed_child) *
                  ((100 - Number(checkMasterRatePlan.default_rate_plan_price.rate.enum)) /
                    100)
                : checkMasterRatePlan?.default_rate_plan_price?.rate?.type == "increase"
                ? Number(self.edit_price_extra_bed_child) *
                  ((100 + Number(checkMasterRatePlan.default_rate_plan_price.rate.enum)) /
                    100)
                : Number(self.edit_price_extra_bed_child);
            new_adult_rate =
              checkMasterRatePlan?.default_rate_plan_price?.rate?.type == "decrease"
                ? Number(self.edit_price_adult_rate) *
                  ((100 - Number(checkMasterRatePlan.default_rate_plan_price.rate.enum)) /
                    100)
                : checkMasterRatePlan?.default_rate_plan_price?.rate?.type == "increase"
                ? Number(self.edit_price_adult_rate) *
                  ((100 + Number(checkMasterRatePlan.default_rate_plan_price.rate.enum)) /
                    100)
                : Number(self.edit_price_adult_rate);
            new_child_rate =
              checkMasterRatePlan?.default_rate_plan_price?.rate?.type == "decrease"
                ? Number(self.edit_price_child_rate) *
                  ((100 - Number(checkMasterRatePlan.default_rate_plan_price.rate.enum)) /
                    100)
                : checkMasterRatePlan?.default_rate_plan_price?.rate?.type == "increase"
                ? Number(self.edit_price_child_rate) *
                  ((100 + Number(checkMasterRatePlan.default_rate_plan_price.rate.enum)) /
                    100)
                : Number(self.edit_price_child_rate);
          }

          el?.package?.map((elem) => {
            tempPackage.push({
              activity: elem?.activity?._id,
              unit: "person",
              base_rate: elem?.base_rate || 0,
              extra_bed_adult: elem?.extra_bed_adult || 0,
              extra_bed_child: elem?.extra_bed_child || 0,
              adult_rate: elem?.adult_rate || 0,
              child_rate: elem?.child_rate || 0,
            });
          });
          tmpRoomUpdateRatePlan.push({
            room: el?.default_rate_plan_price?._id,
            base_rate: Number(el.base_rate) || 0,
            extra_bed_adult: Number(el.extra_bed_adult) || 0,
            extra_bed_child: Number(el.extra_bed_child) || 0,
            adult_rate: Number(el.adult_rate) || 0,
            child_rate: Number(el.child_rate) || 0,
          });
          tempRatePlan.push(
            !self.manualCheckinValue
              ? {
                  default_rate_plan_price: el.default_rate_plan_price._id,
                  base_rate: checkMasterRatePlan
                    ? new_base_rate.toFixed(2)
                    : Number(el.base_rate) || 0,
                  extra_bed_adult: checkMasterRatePlan
                    ? new_extra_adult.toFixed(2)
                    : Number(el.extra_bed_adult) || 0,
                  extra_bed_child: checkMasterRatePlan
                    ? new_extra_child.toFixed(2)
                    : Number(el.extra_bed_child) || 0,
                  adult_rate: checkMasterRatePlan
                    ? new_adult_rate.toFixed(2)
                    : Number(el.adult_rate) || 0,
                  child_rate: checkMasterRatePlan
                    ? new_child_rate.toFixed(2)
                    : Number(el.child_rate) || 0,
                  package: tempPackage,
                  minimum_stay: el.minimum_stay || 0,
                  status: self.edit_price_active == "inactive" ? "inactive" : "active",
                  // el.status
                }
              : {
                  default_rate_plan_price: el.default_rate_plan_price._id,
                  base_rate: checkMasterRatePlan
                    ? new_base_rate.toFixed(2)
                    : Number(el.base_rate) || 0,
                  extra_bed_adult: checkMasterRatePlan
                    ? new_extra_adult.toFixed(2)
                    : Number(el.extra_bed_adult) || 0,
                  extra_bed_child: checkMasterRatePlan
                    ? new_extra_child.toFixed(2)
                    : Number(el.extra_bed_child) || 0,
                  adult_rate: checkMasterRatePlan
                    ? new_adult_rate.toFixed(2)
                    : Number(el.adult_rate) || 0,
                  child_rate: checkMasterRatePlan
                    ? new_child_rate.toFixed(2)
                    : Number(el.child_rate) || 0,
                  package: tempPackage,
                  minimum_stay: el.minimum_stay || 0,
                  // el.status
                }
          );
        }
      });

      var everyroominactive = tempRatePlan?.every((p) => p.status == "inactive");

      const obj = {
        rooms: [
          !self.manualCheckinValue
            ? {
                default_room: self.editPriceItems.rooms.default_room._id,
                room_number: self.editPriceItems.rooms.default_room.room_number,
                room_plan: tempRatePlan,
                status:
                  self.edit_price_active == "inactive" || everyroominactive
                    ? "inactive"
                    : "active",
                // self.editPriceItems.room_status
              }
            : {
                default_room: self.editPriceItems.rooms.default_room._id,
                room_number: self.editPriceItems.rooms.default_room.room_number,
                room_plan: tempRatePlan,
                // status:
                //   self.edit_price_active == "inactive" || everyroominactive
                //     ? "inactive"
                //     : "active",
                // self.editPriceItems.room_status
              },
        ],
      };

      // console.log(
      //   "edit_price_active: ",
      //   self.edit_price_active,
      //   tempRatePlan,
      //   obj,
      //   everyroominactive
      // );

      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/calendar?property=${self.propertyID}&date=${self.editPriceItems.date}`,
          obj,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          swal(
            self.$t("Alert.success_title"),
            self.$t("Alert.save_success"),
            self.$t("Alert.success_label"),
            {
              button: false,
              timer: 2000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.error_title"),
          error?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.error_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        if (
          self.edit_price_active !==
            self.editPriceRatePlan.default_rate_plan_price.status &&
          self.edit_price_active !== "inactive"
        )
          self.updateDefaultRatePlanStatus();
        else {
          self.loadingOverlay = true;
          self.itemsCalendar = [];
          self.itemsDefaultCalendar = [];
          self.editPriceFunc(null, null, null, null, null, true);
        }
      }
    },
    async updateDefaultRatePlanStatus() {
      const self = this;
      const obj = {
        _id: self.editPriceRatePlan.default_rate_plan_price._id,
        status: self.edit_price_active,
      };
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/rate_plan_price?rate_plan_price_id=${obj._id}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");
          EventBus.$emit("loadingPage");
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        self.loadingOverlay = true;
        self.itemsCalendar = [];
        self.itemsDefaultCalendar = [];
        self.editPriceFunc(null, null, null, null, null, true);
      }
    },
    getfocusShowRoomSell(val, attrs, on) {
      var element = document.getElementById(val);
      if (this.value_element_editroom === "" && attrs && on) {
        element.classList.add("bg-yellow-focus");
        this.value_element_editroom = val;
      } else if (this.value_element_editroom && attrs && on) {
        document
          .getElementById(this.value_element_editroom)
          .classList.remove("bg-yellow-focus");
        element.classList.add("bg-yellow-focus");
        this.value_element_editroom = val;
      } else {
        document
          .getElementById(this.value_element_editroom)
          .classList.remove("bg-yellow-focus");
        this.value_element_editroom = "";
      }
    },
    showRoomSellFunc(el, i, ele, index) {
      const self = this;
      var tmpRoomSellItems = [];
      var tempRateplan = [];
      self.loadingShowRoomSellNumber = true;
      if (ele) {
        // console.log(el, i, ele, index)
        tmpRoomSellItems = self.itemsDefaultRoomSell;
        ele.showRoomSell = true;
        self.closeOnShowRoomSell = false;
        self.editRoomDates = el;
        self.editRoomItems = ele;
        self.edit_room_status = el?.room_status || null;
        self.edit_room_number = el?.room_number || 1;
        self.edit_room_number_remain = el?.room_number_remain;
        if (el?.room_number_remain <= 0 && el?.room_status == "noinventory")
          self.itemsRoomSell = tmpRoomSellItems?.filter((p) => p >= el?.room_number);
        else self.itemsRoomSell = self.itemsDefaultRoomSell;
        // console.log('item room sell: ', self.itemsRoomSell, el?.room_status, el?.room_number_remain)
        el?.room_rateplans?.map((elem) => {
          var tempPackage = [];
          elem?.package?.map((element) => {
            tempPackage.push({
              activity: element?.activity?._id,
              unit: element?.unit,
              base_rate: element?.base_rate || null,
              extra_bed_adult: element?.extra_bed_adult || null,
              extra_bed_child: element?.extra_bed_child || null,
              adult_rate: element?.adult_rate || null,
              child_rate: element?.child_rate || null,
            });
          });
          tempRateplan.push({
            default_rate_plan_price: elem.default_rate_plan_price._id,
            base_rate: elem?.base_rate || null,
            extra_bed_adult: elem?.extra_bed_adult || null,
            extra_bed_child: elem?.extra_bed_child || null,
            adult_rate: elem?.adult_rate || null,
            child_rate: elem?.child_rate || null,
            package: tempPackage,
            minimum_stay: elem?.minimum_stay || null,
            status: elem?.status || null,
          });
        });
        self.edit_room_rateplan = tempRateplan;
        setTimeout(() => {
          self.loadingShowRoomSellNumber = false;
        }, 300);
      } else {
        self.closeOnShowRoomSell = true;
        self.editRoomDates = null;
        self.editRoomItems = null;
        self.edit_room_number = 0;
        self.edit_room_number_remain = 0;
        self.itemsRoomSell = [];
        self.edit_room_rateplan = [];
        self.edit_room_status = null;
        self.componentEditRoom = 0;
      }
    },
    getfocusEditRoom(val, attrs, on) {
      var element = document.getElementById(val);
      if (this.value_element_editroom === "" && attrs && on) {
        element.classList.add("bg-yellow-focus");
        this.value_element_editroom = val;
      } else if (this.value_element_editroom && attrs && on) {
        document
          .getElementById(this.value_element_editroom)
          .classList.remove("bg-yellow-focus");
        element.classList.add("bg-yellow-focus");
        this.value_element_editroom = val;
      } else {
        document
          .getElementById(this.value_element_editroom)
          .classList.remove("bg-yellow-focus");
        this.value_element_editroom = "";
      }
    },
    editRoomFunc(el, i, ele, index) {
      const self = this;
      var tmpRoomSellItems = [];
      var tmpRoomCanSell = [];
      var tempRateplan = [];
      self.loadingEditRoomNumber = true;
      if (ele) {
        // console.log(el, i, ele, index);
        tmpRoomSellItems = self.itemsDefaultRoomSell;
        ele.showEditPrice = true;
        self.closeOnEditRoom = false;
        self.editRoomDates = el;
        self.editRoomItems = ele;
        self.edit_room_status = el?.room_status || null;
        self.edit_room_number = el?.room_number || 1;
        self.edit_room_number_remain = el?.room_number_remain;
        tmpRoomCanSell = self.itemsDefaultRoomSell?.filter((p) => p >= el?.room_number_use);
        // console.log('tmpRoomCanSell: ', tmpRoomCanSell)
        if (el?.room_number_remain <= 0 && el?.room_status == "noinventory")
          self.itemsRoomSell = tmpRoomSellItems?.filter((p) => p >= el?.room_number);
        else self.itemsRoomSell = el?.room_number_use ? tmpRoomCanSell : self.itemsDefaultRoomSell;
        // console.log('item room sell: ', self.itemsRoomSell, el?.room_status, el?.room_number_remain)
        el?.room_rateplans?.map((elem) => {
          var tempPackage = [];
          elem?.package?.map((element) => {
            tempPackage.push({
              activity: element?.activity?._id,
              unit: element?.unit,
              base_rate: element?.base_rate || null,
              extra_bed_adult: element?.extra_bed_adult || null,
              extra_bed_child: element?.extra_bed_child || null,
              adult_rate: element?.adult_rate || null,
              child_rate: element?.child_rate || null,
            });
          });
          tempRateplan.push({
            default_rate_plan_price: elem.default_rate_plan_price._id,
            base_rate: elem?.base_rate || null,
            extra_bed_adult: elem?.extra_bed_adult || null,
            extra_bed_child: elem?.extra_bed_child || null,
            adult_rate: elem?.adult_rate || null,
            child_rate: elem?.child_rate || null,
            package: tempPackage,
            minimum_stay: elem?.minimum_stay || null,
            status: elem?.status || null,
          });
        });
        self.edit_room_rateplan = tempRateplan;
        // console.log(self.edit_room_rateplan);
        // self.forceReEditRoomFunc();
        setTimeout(() => {
          self.loadingEditRoomNumber = false;
        }, 300);
      } else {
        self.closeOnEditRoom = true;
        self.editRoomDates = null;
        self.editRoomItems = null;
        self.edit_room_number = 0;
        self.edit_room_number_remain = 0;
        self.itemsRoomSell = [];
        self.edit_room_rateplan = [];
        self.edit_room_status = null;
        self.componentEditRoom = 0;
      }
    },
    selectEditRoomNumberFunc() {
      const self = this;
      // console.log(self.edit_room_number, self?.editRoomDates)
      if (
        self.editRoomDates?.rooms?.default_room?._id &&
        self?.editRoomDates?.room_status == "noinventory"
      ) {
        if (self.edit_room_number_remain <= 0) {
          if (self.edit_room_number > self?.editRoomDates?.room_number)
            self.edit_room_status = "active";
          else self.edit_room_status = "noinventory";
        }
      }
      // if (self.editRoomDates?.rooms?._id && self.edit_room_number < 1)
      //   self.edit_room_status = "inactive";
      // else if (self.editRoomDates?.rooms?._id && self.edit_room_number > 0 && self.edit_room_number > self?.editRoomDates?.room_number)
      //   self.edit_room_status = "active";
      self.forceReEditRoomFunc();
      // console.log(
      //   self.edit_room_number,
      //   self.edit_room_status,
      //   self.componentEditRoom
      // );
    },
    selectEditRoomStatusFunc() {
      const self = this;
      // console.log(self.edit_room_number, self?.editRoomDates)
      if (
        self.editRoomDates?.rooms?.default_room?._id &&
        self?.editRoomDates?.room_number_remain <= 0
      ) {
        if (self.edit_room_status == "active") {
          self.edit_room_number = self?.editRoomDates?.room_number + 1;
        } else self.edit_room_number = self?.editRoomDates?.room_number;
      }
      // if (
      //   self.editRoomDates?.rooms?._id &&
      //   !self.editRoomDates?.rooms?.room_number &&
      //   self.edit_room_status == "active"
      // )
      //   self.edit_room_number = 1;
      // else if (
      //   self.editRoomDates?.rooms?._id &&
      //   self.edit_room_status == "inactive"
      // )
      //   self.edit_room_number = 0;
      self.forceReEditRoomFunc();
    },
    forceReEditRoomFunc() {
      const self = this;
      self.loadingEditRoomNumber = true;
      self.componentEditRoom += 1;
      setTimeout(() => {
        self.loadingEditRoomNumber = false;
      }, 100);
    },
    dateEditRoomText() {
      if (this.editRoomDates?.date) {
        return moment(this.editRoomDates.date).format("DD MMM YYYY");
      } else return "";
    },
    async saveEditRoomFunc() {
      const self = this;
      var tmpRooms = [];
      self.edit_room_rateplan?.map((room) => {
        tmpRooms.push({ ...room, status: self.edit_room_status });
      });

      const obj = {
        rooms: [
          {
            default_room: self.editRoomItems.default_room._id,
            room_number: self.edit_room_number,
            room_plan: tmpRooms,
            status: self.edit_room_status,
          },
        ],
      };

      // console.log("obj: ", obj);

      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/calendar?property=${self.propertyID}&date=${self.editRoomDates.date}`,
          obj,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          self.editRoomItems.showEditPrice = false;
          swal(
            self.$t("Alert.success_title"),
            self.$t("Alert.save_success"),
            self.$t("Alert.success_label"),
            {
              button: false,
              timer: 2000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.error_title"),
          error?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.error_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        EventBus.$emit("endloading");
        self.initAll();
        // self.edit_room_rateplan?.map((el, index) => {
        //   self.updateAllRatePlanRoomDateFunc(el, self.editRoomDates.date, index == self.edit_room_rateplan.length-1)
        // });
      }
    },
    async updateAllRatePlanRoomDateFunc(item, date, lastval) {
      const self = this;
      const obj = {
        rooms: [
          {
            default_room: self.editRoomItems.default_room._id,
            room_number: self.edit_room_number,
            room_plan: tempRatePlan,
            status: self.edit_room_status,
          },
        ],
      };

      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API + `/calendar?property=${self.propertyID}&date=${date}`,
          obj,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.error_title"),
          error?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.error_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        if (lastval) self.initAll().then(() => self.editRoomFunc());
      }
    },
    checkListDays(item) {
      const self = this;
      if (item.check) item.check = !item.check;
      else item.check = true;
    },
    initAll() {
      const self = this;
      // self.loadingtillend();
      self.loadingOverlay = true;
      self.today = moment().format("YYYY-MM-DD");
      self.filter_select_daterange = [
        moment().format("YYYY-MM-DD"),
        moment().add(18, "month").format("YYYY-MM-DD"),
      ];
      self.select_daterange = self.today;
      self.cb_priceadjust = null;
      self.cb_reservereq = null;
      var temp = self.getDates(
        self.filter_select_daterange[0],
        self.filter_select_daterange[1]
      );
      var tempHeaders = [];
      var tempGHeaders = [];
      temp?.map((el) => {
        const findDupMonths = tempGHeaders?.find(
          (p) =>
            p.month_num == moment(el).format("MM") &&
            p.year_num == moment(el).format("YYYY")
        );
        if (el) {
          tempHeaders.push(el);
          if (!findDupMonths)
            tempGHeaders.push({
              name: moment(el).format("MMM YYYY"),
              month_num: moment(el).format("MM"),
              year_num: moment(el).format("YYYY"),
              month_year: moment(el).format("MM_YYYY"),
              counts: 0,
            });
        }
      });
      const counts = {};
      tempHeaders.forEach((x) => {
        counts[moment(x).format("MM_YYYY")] =
          (counts[moment(x).format("MM_YYYY")] || 0) + 1;
      });
      tempGHeaders?.map((ele) => {
        if (counts[ele.month_year]) {
          ele.counts = counts[ele.month_year];
        }
      });
      self.headers = tempHeaders;
      self.groupHeaders = tempGHeaders;

      self.itemsDays = [
        {
          _id: 0,
          value: "sunday",
          name: self.$t("SigninWeekend.sunday"),
          check: false,
        },
        {
          _id: 1,
          value: "monday",
          name: self.$t("SigninWeekend.monday"),
          check: false,
        },
        {
          _id: 2,
          value: "tuesday",
          name: self.$t("SigninWeekend.tuesday"),
          check: false,
        },
        {
          _id: 3,
          value: "wednesday",
          name: self.$t("SigninWeekend.wednesday"),
          check: false,
        },
        {
          _id: 4,
          value: "thursday",
          name: self.$t("SigninWeekend.thursday"),
          check: false,
        },
        {
          _id: 5,
          value: "friday",
          name: self.$t("SigninWeekend.friday"),
          check: false,
        },
        {
          _id: 6,
          value: "saturday",
          name: self.$t("SigninWeekend.saturday"),
          check: false,
        },
      ];

      self.initPropertyCalendarData();
      self.fetchFilterRoomsFunc();
    },
    async fetchFilterRoomsFunc() {
      const self = this;
      var temp = [
        {
          name: self.$t("SigninCalendar.tt_all_room_types"),
          value: null,
        },
      ];
      self.itemsFilterRooms = [];

      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/calendar/room_rate_plan_list?property_id=${
              self?.propertyID || self?.$route?.params?.propertyId
            }`,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          res?.data?.result?.map((el) => {
            temp.push({
              name: el?.room_name || "",
              value: el?._id || "",
            });
          });
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        self.itemsFilterRooms = temp;
      }
    },
    async initPropertyCalendarData() {
      const self = this;
      var temp = null;
      var tempRooms = [
        {
          name: self.$t("SigninCalendar.tt_multiselect"),
          value: null,
        },
      ];
      self.manualCheckinValue = null;
      // EventBus.$emit("loadingtillend");
      // self.loadingtillend();
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${self?.propertyID || self?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0] || null;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        self.propertyData = temp;
        // console.log("property: ", temp);
        self.manualCheckinValue = temp?.manual_status || false;
        // console.log("manualCheckinValue: ", self.manualCheckinValue);
        temp?.rooms?.map((el) => {
          tempRooms.push({
            name: el?.room_name?.value,
            value: el?._id,
          });
        });
        self.itemsAllRooms = tempRooms;
        self.itemsDefaultActivity = temp?.activities || [];

        var tempRoomsCalendar = [];
        self.itemsAllRooms?.map((el) => {
          if (el?.value) {
            if (self.select_room && self.select_room == el.value) {
              tempRoomsCalendar.push({
                ...el,
              });
            } else if (!self.select_room) {
              tempRoomsCalendar.push({
                ...el,
              });
            }
          }
        });
        self.itemsRoomCalendar = tempRoomsCalendar;
        self.initRatePlan();
        self.endloading();
        EventBus.$emit("endloading");
      }
    },
    async initRatePlan() {
      const self = this;
      var temp = [];
      // self.loadingtillend();
      // EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property/rate_plan_price?property_id=${self.propertyID}`,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          if (res?.data?.result?.length) {
            res?.data?.result?.map((el) => {
              temp.push({
                ...el,
                rateplan_id: el?.rate_plan?._id || el?._id,
                rateplan_name: el?.rate_plan?.value || el?.name,
              });
            });
          }
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        self.itemsDefaultRatePlan = temp;
        self.fetchCalendarData();
        self.endloading();
        EventBus.$emit("endloading");
      }
    },
    async filterRoomsFunc() {
      const self = this;
      self.fetchCalendarData();
    },
    async updateManualCheckinFunc() {
      const self = this;
      self.loadingManualCheckin = true;
      // console.log(self.manualCheckinValue, value)
      var updateInfo = {
        manual_status: self.manualCheckinValue,
      };
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            "/property/general_info?property_id=" +
            self.propertyID,
          updateInfo,
          {
            headers: {
              Authorization: self.userToken || localStorage?.getItem("Token"),
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        // console.log("update: ", updateInfo, res);
        self.manualCheckinValue = null;
      } catch (error) {
        self.loadingManualCheckin = false;
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        self.loadingManualCheckin = false;
        self.itemsCalendar = [];
        self.initAll();
      }
    },
    async fetchCalendarData() {
      const self = this;
      var temp = [];
      var tmpCalendar = [];
      self.itemsCalendar = [];
      self.itemsDefaultCalendar = [];
      // EventBus.$emit("loadingtillend");
      self.loadingManualCheckin = true;
      self.loadingOverlay = true;
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/calendar?property=${
              self?.propertyID || self?.$route?.params?.propertyId
            }&start_date=${self.filter_select_daterange[0]}&end_date=${
              self.filter_select_daterange[1]
            }${
              self.select_filter_room ? `&room_name_id=${self.select_filter_room}` : ""
            }`,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          res?.data?.result?.map((el) => {
            const findDate = temp?.find((p) => p.date == el.date);
            if (!findDate) temp.push(el);
          });
        }
      } catch (error) {
        self.loadingManualCheckin = false;
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        self.loadingManualCheckin = false;
        // self.itemsCalendar = temp;
        var tmpRatePlan = [];
        // console.log("calendar: ", temp);
        temp?.map((el, i) => {
          el?.rooms?.map((ele, index) => {
            tmpRatePlan = [];
            const found = tmpCalendar.some((p) => p._id == ele?.default_room?._id);
            const indexFound = tmpCalendar.findIndex(
              (p) => p._id == ele?.default_room?._id
            );
            const foundRoomsOfProperty = self.propertyData?.rooms?.find(
              (p) => p._id == ele?.default_room?._id
            );

            if (ele.room_plan?.length) {
              var allInactiveValue = ele.room_plan.every((e) => {
                // if (e?.minimum_stay < 2) console.log("e", e, el);
                return e?.status == "inactive";
              });
              ele.room_plan?.map((ee, ini) => {
                const foundRatePlan = tmpRatePlan.some(
                  (p) =>
                    p?.default_rate_plan_price?._id == ee?.default_rate_plan_price?._id
                );
                const indexRatePlan = tmpRatePlan.findIndex(
                  (p) =>
                    p?.default_rate_plan_price?._id == ee?.default_rate_plan_price?._id
                );
                const foundDefaultRatePlan = self.itemsDefaultRatePlan?.find(
                  (p) => p?._id == ee?.default_rate_plan_price?._id
                );
                if (!foundRatePlan) {
                  tmpRatePlan.push({
                    default_rate_plan_price: ee.default_rate_plan_price,
                    showalloptions: false,
                    haveoptions:
                      ee?.base_rate || ee?.adult_rate || ee.minimum_stay > 1
                        ? true
                        : false,
                    showrateperperson: false,
                    haverateperperson: ee?.base_rate || ee?.adult_rate ? true : false,
                    showminimumstay: false,
                    haveminimumstay: ee.minimum_stay > 1 ? true : false,
                    room: ele?.default_room,
                    room_status: ele?.status,
                    room_dates: [
                      {
                        date: el?.date,
                        room: ele?.default_room,
                        rate_plan: ee,
                      },
                    ],
                    customer_normal: ele?.customer_normal,
                    customer_max: ele?.customer_max,
                    rateplan_id: ee?.default_rate_plan_price?._id,
                    rateplan_name:
                      ee?.default_rate_plan_price?.rate_plan?.value ||
                      ee?.default_rate_plan_price?.name,
                  });
                } else {
                  tmpRatePlan[indexRatePlan].room_dates.push({
                    dates: el.date,
                    room: ele?.default_room,
                    rate_plan: ee,
                  });
                }
              });
            }
            if (!found) {
              tmpCalendar.push({
                _id: ele.default_room._id,
                default_room: ele.default_room,
                room_status: ele.status,
                rate_plans: tmpRatePlan,
                room_dates: [
                  {
                    date: el.date,
                    room_number: ele.room_number,
                    room_number_use: ele.room_number_use,
                    room_number_remain:
                      ele.room_number - (ele.room_number_use || 0) < 0
                        ? 0
                        : ele.room_number - (ele.room_number_use || 0),
                    room_customer_normal: ele.customer_normal,
                    room_customer_max: ele.customer_max,
                    room_status:
                      ele.status == "inactive" ||
                      self.manualCheckinValue ||
                      allInactiveValue
                        ? "inactive"
                        : ele.room_number - (ele.room_number_use || 0) <= 0
                        ? "noinventory"
                        : "active",
                    room_rateplans: ele.room_plan,
                    // base_rate: ele.room_plan.base_rate,
                    rooms: ele,
                    property_room_detail: foundRoomsOfProperty,
                  },
                ],
                room_dates_status: [
                  {
                    date: el.date,
                    room_number: ele.room_number,
                    room_number_use: ele.room_number_use,
                    room_number_remain:
                      ele.room_number - (ele.room_number_use || 0) < 0
                        ? 0
                        : ele.room_number - (ele.room_number_use || 0),
                    room_customer_normal: ele.customer_normal,
                    room_customer_max: ele.customer_max,
                    room_status:
                      ele.status == "inactive" ||
                      self.manualCheckinValue ||
                      allInactiveValue
                        ? "inactive"
                        : ele.room_number - (ele.room_number_use || 0) <= 0
                        ? "noinventory"
                        : "active",
                    room_rateplans: ele.room_plan,
                    // base_rate: ele.room_plan.base_rate,
                    rooms: ele,
                    property_room_detail: foundRoomsOfProperty,
                  },
                ],
              });
            } else {
              tmpCalendar[indexFound].room_dates.push({
                date: el.date,
                room_number: ele.room_number,
                room_number_use: ele.room_number_use,
                room_number_remain:
                  ele.room_number - (ele.room_number_use || 0) < 0
                    ? 0
                    : ele.room_number - (ele.room_number_use || 0),
                room_customer_normal: ele.customer_normal,
                room_customer_max: ele.customer_max,
                room_status:
                  ele.status == "inactive" || self.manualCheckinValue || allInactiveValue
                    ? "inactive"
                    : ele.room_number - (ele.room_number_use || 0) <= 0
                    ? "noinventory"
                    : "active",
                room_rateplans: ele.room_plan,
                // base_rate: ele.room_plan.base_rate,
                rooms: ele,
                property_room_detail: foundRoomsOfProperty,
              });
              tmpCalendar[indexFound].room_dates_status.push({
                date: el.date,
                room_number: ele.room_number,
                room_number_use: ele.room_number_use,
                room_number_remain:
                  ele.room_number - (ele.room_number_use || 0) < 0
                    ? 0
                    : ele.room_number - (ele.room_number_use || 0),
                room_customer_normal: ele.customer_normal,
                room_customer_max: ele.customer_max,
                room_status:
                  ele.status == "inactive" || self.manualCheckinValue || allInactiveValue
                    ? "inactive"
                    : ele.room_number - (ele.room_number_use || 0) <= 0
                    ? "noinventory"
                    : "active",
                room_rateplans: ele.room_plan,
                // base_rate: ele.room_plan.base_rate,
                rooms: ele,
                property_room_detail: foundRoomsOfProperty,
              });
            }
          });
        });
        tmpCalendar?.map((el) => {
          const sortDates = el.room_dates.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
          });
          const sortDates2 = el.room_dates_status.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          });
        });
        self.itemsCalendar = tmpCalendar;
        self.itemsDefaultCalendar = tmpCalendar;
        // console.log("tmpCalendar: ", self.itemsCalendar);
        EventBus.$emit("endloading");
        self.endloading();
        self.loadingOverlay = false;
      }
    },
    checkRoomStatusFunc(el) {
      const self = this;
      // console.log(el)
      var blackoutValue = el?.room_status == "inactive";
      var noInventoryValue =
        el?.room_status == "noinventory" || el?.room_number_remain <= 0 || false;
      var allActiveValue = el?.room_rateplans.every((e) => {
        // if (e?.minimum_stay < 2) console.log("e", e, el);
        return e?.status == "active";
      });
      // if (el?.property_room_detail?.room_number == 0)
      // console.log(el?.property_room_detail?.room_number)
      return blackoutValue
        ? self.$t("SigninCalendar.bulk_not_reserve_room")
        : noInventoryValue
        ? self.$t("SigninCalendar.status_no_inventory")
        : el?.room_number_remain > 0
        ? allActiveValue
          ? self.$t("SigninCalendar.status_bookable")
          : self.$t("SigninCalendar.bulk_not_reserve_room")
        : self.$t("SigninCalendar.status_no_inventory");
    },
    checkRoomRatePlan(item, ii, ee, i, ele, index) {
      const self = this;
      const findRoomPrice = ee?.default_rate_plan_price?.rooms?.find(
        (p) => p.room?._id == item.rooms?.default_room?._id
      );
      var perVoucherValue = null;
      var perGuestValue = null;
      var pricePerVoucher = 0;
      var pricePerGuest = 0;
      var priceOfRoom = 0;
      var sumActivity = 0;
      var normalPerRoom = 1;

      normalPerRoom = item?.room_customer_normal || 1;
      // console.log('rooms: ', normalPerRoom, item, item?.room_customer_normal)
      if (
        ele?.room_dates[ii]?.room_rateplans[i]?.default_rate_plan_price?.per_room ||
        ee.default_rate_plan_price?.per_room
      ) {
        perVoucherValue = true;
        if (ee?.default_rate_plan_price?.master_rate_plan_price) {
          pricePerVoucher =
            item?.room_rateplans[i]?.base_rate || findRoomPrice?.base_rate;
        } else {
          pricePerVoucher = item?.room_rateplans[i]?.base_rate || 0;
          // ele?.room_dates[ii]?.room_rateplans[i]?.base_rate ||
          // ee?.default_rate_plan_price?.rooms[i]?.base_rate ||
          // findRoomPrice?.base_rate;
        }
      } else if (
        ele?.room_dates[ii]?.room_rateplans[i]?.default_rate_plan_price?.per_guest ||
        ee.default_rate_plan_price?.per_guest
      ) {
        perGuestValue = true;
        if (ee?.default_rate_plan_price?.master_rate_plan_price) {
          pricePerGuest =
            (item?.room_rateplans[i]?.adult_rate || findRoomPrice?.adult_rate) *
            normalPerRoom;
        } else {
          pricePerGuest =
            (item?.room_rateplans[i]?.adult_rate || 0) *
            // ele?.room_dates[ii]?.room_rateplans[i]?.adult_rate ||
            // ee?.default_rate_plan_price?.rooms[i]?.adult_rate ||
            // findRoomPrice?.adult_rate
            normalPerRoom;
        }
      } else {
        priceOfRoom =
          item?.room_rateplans[i]?.base_rate ||
          findRoomPrice?.base_rate ||
          (item?.room_rateplans[i]?.adult_rate || findRoomPrice?.adult_rate) *
            normalPerRoom ||
          0;
      }

      if (
        ele?.room_dates[ii]?.room_rateplans[i]?.default_rate_plan_price?.package?.length
      ) {
        ele?.room_dates[ii]?.room_rateplans[i]?.default_rate_plan_price?.package?.map(
          (element) => {
            if (element?.unit == "voucher") sumActivity += element?.base_rate;
            else if (element?.unit == "person")
              sumActivity += element?.adult_rate * normalPerRoom;
          }
        );
      }

      // if (sumActivity == 200) console.log(item, ii, ee, i, ele, index, 'sumActivity: ', sumActivity, perVoucherValue, pricePerVoucher,perGuestValue pricePerGuest, priceOfRoom)

      return perVoucherValue && pricePerVoucher
        ? self.priceFormatter((pricePerVoucher || 0) + (sumActivity || 0))
        : perGuestValue && pricePerGuest
        ? self.priceFormatter((pricePerGuest || 0) + (sumActivity || 0))
        : priceOfRoom
        ? self.priceFormatter((priceOfRoom || 0) + (sumActivity || 0))
        : self.priceFormatter(0);
    },
    checkRoomRatePlanPerNight(item, ii, ee, i, ele, index) {
      const self = this;
      const findRoomPrice = ee?.default_rate_plan_price?.rooms?.find(
        (p) => p.room?._id == item.rooms?.default_room?._id
      );

      var perVoucherValue = null;
      var perGuestValue = null;
      var pricePerVoucher = 0;
      var pricePerGuest = 0;
      var priceOfRoom = 0;
      var sumActivity = 0;
      var normalPerRoom = 1;
      var total = 0;

      if (
        ele?.room_dates[ii]?.room_rateplans[i]?.default_rate_plan_price?.per_room ||
        ee.default_rate_plan_price?.per_room
      ) {
        perVoucherValue = true;

        if (ee?.default_rate_plan_price?.master_rate_plan_price) {
          pricePerVoucher =
            item?.room_rateplans[i]?.base_rate || findRoomPrice?.base_rate;
        } else {
          pricePerVoucher =
            item?.room_rateplans[i]?.base_rate ||
            ele?.room_dates[ii]?.room_rateplans[i]?.base_rate ||
            ee?.default_rate_plan_price?.rooms[i]?.base_rate ||
            findRoomPrice?.base_rate;
        }
      } else if (
        ele?.room_dates[ii]?.room_rateplans[i]?.default_rate_plan_price?.per_guest ||
        ee.default_rate_plan_price?.per_guest
      ) {
        perGuestValue = true;
        if (ee?.default_rate_plan_price?.master_rate_plan_price) {
          pricePerGuest =
            (item?.room_rateplans[i]?.adult_rate || findRoomPrice?.adult_rate) *
            normalPerRoom;
        } else {
          pricePerGuest =
            (item?.room_rateplans[i]?.adult_rate ||
              ele?.room_dates[ii]?.room_rateplans[i]?.adult_rate ||
              ee?.default_rate_plan_price?.rooms[i]?.adult_rate ||
              findRoomPrice?.adult_rate) * normalPerRoom;
        }
      } else {
        priceOfRoom =
          item?.room_rateplans[i]?.base_rate ||
          findRoomPrice?.base_rate ||
          (item?.room_rateplans[i]?.adult_rate || findRoomPrice?.adult_rate) *
            normalPerRoom ||
          0;
      }

      if (
        ele?.room_dates[ii]?.room_rateplans[i]?.default_rate_plan_price?.package?.length
      ) {
        ele?.room_dates[ii]?.room_rateplans[i]?.default_rate_plan_price?.package?.map(
          (element) => {
            if (element?.unit == "voucher") sumActivity += element?.base_rate;
            else if (element?.unit == "person") sumActivity += element?.adult_rate * 1;
          }
        );
      }

      if (perVoucherValue) total = (pricePerVoucher || 0) + (sumActivity || 0);
      else if (perGuestValue) total = (pricePerGuest || 0) + (sumActivity || 0);
      else total = (priceOfRoom || 0) + (sumActivity || 0);

      return self.priceFormatter(total);
    },
    checkRoomRatePlanMinimumStay(eel, ii, ee, i, ele, index) {
      // const self = this;
      // console.log(
      //   item?.room_rateplans[i],
      //   ele?.room_dates[ii]?.room_rateplans[i]?.default_rate_plan_price,
      //   ee.default_rate_plan_price
      // );
      // console.log(eel, ii, ee, i, ele, index)
      return (
        Number(
          eel?.room_rateplans[i]?.minimum_stay || ee?.minimum_stay
          // || eel?.room_rateplans[i]?.minimum_stay ||
          //   ele?.room_dates[ii]?.room_rateplans[i]?.default_rate_plan_price
          //     ?.minimum_stay ||
          //   ee.default_rate_plan_price?.minimum_stay
        ) || ""
      );
    },
    checkPriceAdjust() {
      const self = this;
      self.cb_priceadjust = !self.cb_priceadjust;
      self.itemsCalendar?.map((el) => {
        el?.rate_plans?.map((ele) => {
          if (self?.cb_priceadjust && ele.haverateperperson) {
            if ((self?.cb_reservereq && ele?.haveminimumstay) || !ele?.haveminimumstay)
              ele.showalloptions = true;
            ele.showrateperperson = true;
          } else {
            if (self?.cb_reservereq && ele?.haveminimumstay && !ele?.haverateperperson)
              ele.showalloptions = true;
            else ele.showalloptions = false;
            ele.showrateperperson = false;
          }
        });
      });
    },
    checkReserveReq() {
      const self = this;
      self.cb_reservereq = !self.cb_reservereq;
      self.itemsCalendar?.map((el) => {
        el?.rate_plans?.map((ele) => {
          if (self?.cb_reservereq && ele.haveminimumstay) {
            if (
              (self?.cb_priceadjust && ele?.haverateperperson) ||
              !ele?.haverateperperson
            )
              ele.showalloptions = true;
            ele.showminimumstay = true;
          } else {
            if (self?.cb_priceadjust && ele?.haverateperperson && !ele.haveminimumstay)
              ele.showalloptions = true;
            else ele.showalloptions = false;
            ele.showminimumstay = false;
          }
        });
      });
    },
    nameOfMonth(month) {
      return moment(month).format("MMMM YYYY");
    },
    startMonthSelect(month) {
      return moment(month).format("YYYY-MM-DD");
    },
    saveSelectDateRange() {
      const self = this;
      // self.select_daterange.sort();
      self.filter_select_daterange = [
        self.select_daterange,
        moment(self.select_daterange).add(18, "month").format("YYYY-MM-DD"),
      ];
      // self.filter_select_daterange = self.select_daterange.sort();
      self.$refs.selectdaterange.save(self.select_daterange);
      self.today = self.filter_select_daterange[0];
      var temp = self.getDates(
        self.filter_select_daterange[0],
        self.filter_select_daterange[1]
      );
      var tempHeaders = [];
      var tempGHeaders = [];
      temp?.map((el) => {
        const findDupMonths = tempGHeaders?.find(
          (p) =>
            p.month_num == moment(el).format("MM") &&
            p.year_num == moment(el).format("YYYY")
        );
        if (el) {
          tempHeaders.push(el);
          if (!findDupMonths)
            tempGHeaders.push({
              name: moment(el).format("MMM YYYY"),
              month_num: moment(el).format("MM"),
              year_num: moment(el).format("YYYY"),
              month_year: moment(el).format("MM_YYYY"),
              counts: 0,
            });
        }
      });
      const counts = {};
      tempHeaders.forEach((x) => {
        counts[moment(x).format("MM_YYYY")] =
          (counts[moment(x).format("MM_YYYY")] || 0) + 1;
      });
      tempGHeaders?.map((ele) => {
        if (counts[ele.month_year]) {
          ele.counts = counts[ele.month_year];
        }
      });
      self.headers = tempHeaders;
      self.groupHeaders = tempGHeaders;

      self.menu_daterange = false;
      setTimeout(() => {
        self.fetchCalendarData();
      }, 500);
    },
    getToday() {
      const self = this;
      self.initAll();
    },
    getDates(startDate, stopDate) {
      var dateArray = [];
      var currentDate = moment(startDate);
      var tmpStopDate = moment(stopDate);
      while (currentDate <= tmpStopDate) {
        dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }
      return dateArray;
    },
    showMDaysHeader(day) {
      return moment(day).format("MMM YYYY");
    },
    showDaysHeader(day) {
      return moment(day).format("DD");
    },
    showDOHeader(day) {
      return moment(day).format("ddd");
    },
    nameOfMonth(month) {
      return moment(month).format("MMMM YYYY");
    },
    startMonthSelect(month) {
      return moment(month).format("YYYY-MM-DD");
    },
    viewDay({ date }) {
      const self = this;
      const findData = self.items_holiday_and_special.find((p) => p.date == date);
      if (findData) self.specialHoliday = true;
      else self.specialHoliday = false;
      self.select_date = date;
      self.dialogHoliday = true;
      self.addHolidayText = findData?.name || "";
      self.addHolidayType = findData?.type || "special_holiday";
      if (findData?.type == "special_holiday") {
        self.checkSpecialHoliday();
        self.addHoliday = false;
      } else if (findData?.type == "holiday") {
        self.specialHoliday = null;
        self.addHoliday = true;
      } else {
        self.specialHoliday = null;
        self.addHoliday = null;
      }
    },
    getEventColor(event) {
      return event.color;
    },
    async updateRange({ start, end }) {
      const self = this;
      self.events = [];
      var events = [];

      try {
        await self?.items_holiday_and_special?.map((el) => {
          if (el) {
            const min = new Date(`${el.date}T00:00:00`).getTime();
            const max = new Date(`${el.date}T23:59:59`).getTime();
            const allDay = self.rnd(0, 3) === 0;
            const firstTimestamp = self.rnd(min, max);
            const first = new Date(firstTimestamp - (firstTimestamp % 900000));

            events.push({
              // name: self.names[self.rnd(0, self.names.length - 1)],
              name:
                el.type == "holiday"
                  ? el.name
                  : self.$t("SigninWeekend.label_special_holiday"),
              start: el.date,
              end: el.date,
              color:
                el.type == "holiday"
                  ? self.colors[0]
                  : self.colors[self.colors.length - 1],
              timed: allDay,
            });
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        self.events = events;
      }
    },
    async refreshCalendarData() {
      const self = this;
      // EventBus.$emit("loadingtillend");
      self.itemSelectMonths?.map((el) => {
        self.updateRange(
          moment(el).format("YYYY-MM-01"),
          moment(el).format("YYYY-MM-31")
        );
      });
      EventBus.$emit("endloading");
    },
    async updateTimeoutAppFunc() {
      const self = this;

      const obj = {
        active_app: false,
      };
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/team_purchase?property_id=${self?.propertyID}`,
          obj,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    gotoTeamPurchaseFunc() {
      const self = this;
      const link = {
        name: "SigninTeamPurchaseScreen",
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
        },
      };
      self.gotofillmoredata(link);
    },
  },
};
</script>

<style scoped>
@import "../../../styles/bootstrap/global.css";
/* @import "../../styles/signin/components.css"; */
@import "../../../styles/signin/styledashboard.css";
/* @import "../../styles/signin/line-awesome/css/line-awesome.css";
@import "../../styles/signin/line-awesome/css/line-awesome.min.css"; */

.btn-exit-signup {
  cursor: pointer !important;
}
.nav-main > li.nav-signup > a:hover {
  background-color: var(--pink);
  color: #fff;
  border-color: var(--pink);
}

.navbar-shadow-signin {
  z-index: 99 !important;
  box-shadow: 0px 2px 30px 2px rgb(0 0 0 / 10%) !important;
}

@media (max-width: 991.98px) {
  .navbar-device {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    width: 300px;
    overflow: hidden;
    padding: 0;
    z-index: 1000;

    pointer-events: none;
    display: none;

    align-items: unset;
    -ms-flex-align: unset;
    background-color: #262262;
  }

  .nav-opened .navbar-device {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .closing .navbar-device {
    display: flex;
    pointer-events: auto;
  }

  .nav {
    display: flex;
    flex-wrap: inherit !important;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .position-vmain {
    position: relative;
    z-index: 1;
  }
}

.position-vmain {
  position: relative;
  z-index: 1;
}

.top-navbar {
  background-color: white;
  box-shadow: 0px 2px 30px 2px rgb(0 0 0 / 10%);
  height: 90px;
  align-items: center;
}

.menu-title {
  font-size: 19px;
  letter-spacing: 0.2px;
  font-weight: 400;
  font-size: 1.1rem;
  /* font-family: "Quicksand", Georgia, "Times New Roman", Times, serif !important;  */
}

.header-list {
  background-color: #0a1a2a;
  height: 48px;
}

.menu-list {
  border: none;
  background-color: #152638;
  color: #fff;
  cursor: pointer;
}

.span-sub-list:hover {
  margin-left: 15px;
}

.activeClass {
  border-right: #e83e8c solid 5px;
}

.user-menu {
  margin-bottom: 0px;
}

.user-menu p span {
  font-size: 12px;
  cursor: pointer;
}

.user-menu p:hover {
  font-size: 13px;
  cursor: pointer;
  margin-left: 10px;
}
</style>

<style scoped>
@import "../../../styles/signin/holiday.css";
@import "../../../styles/signin/rateplan.css";
@import "../../../styles/signin/promotion.css";
@import "../../../styles/signin/calendar.css";
@import "../../../styles/bootstrap/bootstrap.css";

#promotioninactive tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
  background-color: #6b6f82 !important;
}

.v-menu__content {
  max-width: 290px !important;
}

.input-date-select {
  font-size: 0.875rem !important;
}

.input-price-style {
  border: 1px solid #babfc7 !important;
  color: #3b4781;
  border-radius: 5px !important;
}

.btn-light {
  border-color: #e0e0e0 !important;
  background-color: #e0e0e0 !important;
  color: #ffffff;
}

.v-menu__content {
  border: none !important;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px !important;
}

.fixed-month {
  background-color: #fff;
  color: #000;
  transition: transform 0.3s;
}

/* The sticky */
.fixed-month {
  position: -webkit-sticky;
  position: sticky;
  left: 18.75rem;
}

.fixed-last-month b {
  background-color: #fff;
  color: #000;
  transition: transform 0.3s;
}

/* The sticky */
.fixed-last-month b {
  position: -webkit-sticky;
  position: sticky;
  left: 18.75rem;
}
</style>

<style lang="scss" scoped>
@import "../../../styles/bootstrap/global.css";
@import "../../../styles/signin/setting.css";

.border-pink-focus {
  border-color: var(--pink) !important;
}

.bg-yellow-focus {
  background-color: rgb(253, 241, 233) !important;
  color: #bf8061 !important;
}

.bg-gray-focus {
  background-color: rgb(177, 175, 175) !important;
  color: #333 !important;
}

.btn-group {
  border: 1px solid #666ee8;
  max-width: fit-content;
  border-radius: 0px;
  // border-bottom-right-radius: 0px;
  background-color: #fff !important;
  color: #666ee8 !important;
}

.btn-group-active {
  border: 1px solid #666ee8;
  max-width: fit-content;
  border-radius: 0px;
  // border-bottom-right-radius: 0px;
  background-color: #666ee8 !important;
  color: #fff !important;
}
</style>
